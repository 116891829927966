import React, { Component } from 'react';

class ModalTitle extends Component {

    constructor(props){
        super();
    }

    close = (e) => {
        e.preventDefault();
        document.getElementById("modalTitle").style.display = "none";
    }

    render() {
        return(
        <div id="modalTitle" className="modal">
            <div className="modal-body">
                <h2>NODES information: <span>{this.props.inputID}</span> </h2>
                <form>

                    <div className="form-row">
                        <label>Label:</label>
                        <input id="node-label" value={this.props.inputTitle} onChange={(event) => this.props.handleInputTitle(event)} />
                    </div>
                    
                    <div className="form-row">
                        <label>Summary:</label>
                        <textarea maxLength="256" value={this.props.textAreaModalNode} onChange={(event) => this.props.handleTextAreaModalNode(event)} />
                    </div>

                    <div className="modal-btns">
                        <button  onClick={(e) => this.props.handleSaveModalNodes(e)}>SAVE</button>
                        <button className="btn-cancel" onClick={e => this.close(e)}>CANCEL</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

  
export default ModalTitle;  
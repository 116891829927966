import React, { Component } from 'react';

class ModalDialog extends Component {

    constructor(props){
        super(props);


    }

    render() {
        let edgesIdMain = this.props.modalDialog['idEdges'];
        let reactions = this.props.modalDialog['reaction'];
        let action = this.props.modalDialog['action'];
        

        let ways = this.props.otherWays.length > 0 ?
        this.props.otherWays.map( (ways, index)=>
            <button onClick={(e) => this.props.handleOpenChildNode(e, ways)} key={index}>{ways}</button>
        ) : "";

       

        return(
        <div id="modalDialog" className="modal">
            <div className="modal-body">
                <h2>MODAL DIALOG: <span>{this.props.inputID}</span> </h2>
                <p className='nameEdges'>EDGES MAIN ID: {edgesIdMain}</p>

                <div>
                    <div className="modal-btn">
                        <h3>Other ways:</h3>
                        {ways}   
                    </div>
                </div>

                <form>

                    <div className="modal-input">
                        <h3>ACTIONS:</h3>
                        <textarea maxLength="256" value={action} onChange={(e) => this.props.handleTextAreaDialog(e)} />
                    </div>

                    <div>
                        <h3>REACTIONS:</h3>
                        <div className="modal-input">
                            <input 
                                type="text"
                                name="1"
                                value={reactions[1]} 
                                onChange={(e) => this.props.handleInputDialog(e)}
                            />
                            <input 
                                type="text"
                                name="2"
                                value={reactions[2]} 
                                onChange={(e) => this.props.handleInputDialog(e)}
                            />
                            <input 
                                type="text"
                                name="3"
                                value={reactions[3]} 
                                onChange={(e) => this.props.handleInputDialog(e)}
                            />
                            <input 
                                type="text"
                                name="4"
                                value={reactions[4]} 
                                onChange={(e) => this.props.handleInputDialog(e)}
                            />
                         </div>
                        
                    </div>

                    <div className="btn-prev-next"> 
                        <button className={this.props.modalDialog['prevId'] ? "btn-prev" : "disable"} onClick={(e) => this.props.handleOpenChildNode(e, this.props.modalDialog['prevId'])}>PREV : {this.props.modalDialog['prevId']}</button>
                        <button className={this.props.modalDialog['nextId'] ? "btn-next" : "disable"} onClick={(e) => this.props.handleOpenChildNode(e, this.props.modalDialog['nextId'])}>NEXT: {this.props.modalDialog['nextId']}</button>
                    </div>

                    <div className="modal-btns">
                        <button onClick={(e) => this.props.handleSaveDialog(e)}>SAVE</button>
                        <button className="btn-cancel" onClick={(e) => this.props.handleCloseModalDialog(e)}>CANCEL</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

  
export default ModalDialog;  
// Options for Vis
export const options = {
    autoResize: true,
    width: 2700 + 'px',
    height: 2400 + 'px',
    locale: "cs",
    layout: {
        improvedLayout: false,
    },
    manipulation: {
        enabled: false,
        initiallyActive: false,
        addNode: false,
        addEdge: true,
        editEdge: true,
        deleteNode: false,
        deleteEdge: true
      },
    nodes: {
        shapeProperties: {
            interpolation: false,
        },
        borderWidth: 0,
        borderWidthSelected: 1,
        font: {
            size: 12,
            strokeWidth: 0,
            vadjust: 0
        },
    },
    edges: {
        width: 4,
        dashes: false,
        color: {
            color: '#16b7b3',  
            highlight:'red',
            hover: 'green',
            inherit: false,
            opacity:1.0
        },
        shadow:{
            enabled : true,
            color: 'rgba(0,255,248,0.7)',
            size: 20,
            x: 10,
            y: 10
        },
        hoverWidth: 3,
        selectionWidth: 2,
        endPointOffset: {
            from: 0,
            to: 0,
          },
        arrows:{
            from:{
                enabled: true,
                imageHeight: 49,
                imageWidth: 4,
                scaleFactor: 1,
                src: "/img/edges-1.jpg",
                type: "image"            
            },   
            to:{
                enabled: true,
                imageHeight: 49,
                imageWidth: 4,
                scaleFactor: 1,
                src: "/img/edges-1.jpg",
                type: "image"            
            },          
        },
        smooth:{
            enabled: false, // when moving node, edges are directly
        }
    },
    physics: false,
    interaction: {
        hover: true,
        tooltipDelay: 500, //delay for show title on hover node
        dragNodes: true, // allow dragging nodes
        zoomView: true, // allow zooming by mouse wheel
        multiselect: false, // allow multi select přes CMD
        dragView: true,  // allow dragging
        hideEdgesOnDrag: false // tunroff edges on move nodes
    }
};
import React, { Component } from 'react';

class ModalSaveCanvasAs extends Component {

    constructor(props){
        super();
    }

    nameNewFile

    render() {
        return(
        <div id="modalSaveCanvasAs" className="modal">
 
            <div className="modal-body">
            <div className="modal-header">
                <h2>SAVE CANVAS AS</h2>
                <button className="btn--close" onClick={() => this.props.handleCloseModal("modalSaveCanvasAs")} >X</button>
            </div>  
                <p>Please, insert name of Canvas:</p>
                <form>

                    <div className="form-row">
                        <input onChange={(event) => this.props.handleInputNameChange(event)} />
                    </div>

                    <div className="modal-btns">
                        <button onClick={() => this.props.handleCreateFile()}>SAVE</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

  
export default ModalSaveCanvasAs;  
import React, { Component } from 'react';

class ModalSaveDialog extends Component {

    constructor(props){
        super();
    }

    close = (e) => {
        e.preventDefault();
        document.getElementById("modalSaveDialog").style.display = "none";
        this.props.handleStateSaveDialog(false);
    }

    render() {
        return(
        <div id="modalSaveDialog" className="modal">
            <div className="modal-body">
                <div className="modal-header">
                    <h2>Do you save Change?</h2>
                    <button className="btn--close" onClick={e => this.close(e)}>X</button>
                </div>  
                <div className="modal-btns">
                    <button className="abort" onClick={e => this.close(e)}>NO</button>
                    <button className="accept" onClick={(e) => this.props.handleSaveDialogYesNo(e)}>YES</button>

                </div>
            </div>
        </div>
        )
    }
}

export default ModalSaveDialog;  
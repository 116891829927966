export function visSelectNodeInfo(nodeId,nodesJsonData){
let infoOfNode = {
        id: nodesJsonData[nodeId-1].id,
        group: nodesJsonData[nodeId-1].group,
        title: nodesJsonData[nodeId-1].title,
        label: nodesJsonData[nodeId-1].label,
        type: nodesJsonData[nodeId-1].type,
        y: nodesJsonData[nodeId-1].y,
        x: nodesJsonData[nodeId-1].x,
        size: nodesJsonData[nodeId-1].size,
        image: nodesJsonData[nodeId-1].image,
        visibile: nodesJsonData[nodeId-1].visible,
        textarea: nodesJsonData[nodeId-1].textarea
}
return infoOfNode;
}

export function getNodeInfo(nodeId,nodesJsonData){
        let node;
        nodesJsonData.forEach(item =>{
                if (item.id === nodeId){
                        node = {
                            "node" : {
                                "id" : item.id,
                                "x" : item.x,
                                "y" : item.y,
                                "size" : item.size,
                                "image" : item.image
                            }
                        }
                    }

         })
        return node;
}


export function getNearestCentersNodes(x, y, data, centers, size = 3, count = 3){

        //this function find "count" nearest nodes

     let idNearestNodes = [];

        //get distances center from node
        let centersNew = centers.map(item => {
        //count of distance point in 2d
        function distanceXY (){
            return Math.sqrt(Math.pow((item.x - x),2) + Math.pow((item.y - y),2));
        }                    
        return {
            id : item.id,
            x : item.x,
            y : item.y,
            distance : distanceXY()
        }
        });

        centersNew.sort((a,b) => parseFloat(a.distance) - parseFloat(b.distance));
                for (let i = 1; i <= count; i++) {
                        data.forEach(item =>{
                                if (item.x === centersNew[i].x && item.y === centersNew[i].y && item.group === "border"){
                                        idNearestNodes.push(item.id);
                                }
                        })
        };

     return idNearestNodes;

}

export function initCanvas(nodesJsonData){
        //reset NODES
        let filteredNodesJsonData = nodesJsonData.filter(function (item) {
                return item.type != "dropElement" & 
                       item.type != "importedJsonData" & 
                       item.type != "rosetteChildChild" & 
                       item.type != "rosetteChildMain" & 
                       item.type != "rosetteParentChild" & 
                       item.type != "rosetteParentMain"
            });
        let finalFilteredData = {
                "nodesJsonData" : filteredNodesJsonData
        };

        return finalFilteredData;
}
export function setEnvironment(role){
   {

    let capatibilities = [];

    switch (role){
        case "Design":
            capatibilities.push({
                "stateDropNode" : false,
                "stateEditNode" : false,
                "stateImportMindbox" : true,
                "stateImportMetila" : false
            })
            return capatibilities;
        break;
        case "Model":
            capatibilities.push({
                "stateDropNode" : true,
                "stateEditNode" : false,
                "stateImportMindbox" : false,
                "stateImportMetila" : true
            })
            return capatibilities;
        break;
        case "Virtual":
            capatibilities.push({
                "stateDropNode" : false,
                "stateEditNode" : true,
                "stateImportMindbox" : false,
                "stateImportMetila" : false
            })
            return capatibilities;
        break;
        default:
            console.log("Role is not DEFINED");
            return capatibilities;
    };

   }

}

 const getPath = function(x1, y1, x2, y2, size1, size2, nodesStart_type, nodesEnd_type, GetCentersForEdges){
    let gridArray = [];
    let quadrant = 0;
    let nodesStart = findNodes(x1, y1, 6);
    let nodesEnd = findNodes(x2, y2, 6);
    // look out on named of SVG files!!!
    let type1 = nodesStart_type.split("-")[0];
        type1 = type1.split("/")[1];
    let type2 = nodesEnd_type.split("-")[0];
        type2 = type2.split("/")[1];


        //find 6 nearest nodes start
    function findNodes(x, y, count){
        let store = [];
        GetCentersForEdges.forEach(item =>{
           let result = Math.sqrt(Math.pow((item.x - x),2) + Math.pow((item.y - y),2));
           store.push({
               "distance" : result,
               "id" : item.id,
               "x" : item.x,
               "y" : item.y
           });
        })
        //nearest nodes to start
        store.sort(({"distance":a}, {"distance":b}) => a-b);
        //get first 6 nodes
        store = store.slice(0, count);

        /**
         * order of nodes and documentation
         *  6 -->[0], 
         *  5 -->[1], 
         *  4 -->[3], 
         *  3 -->[5], 
         *  2 -->[4], 
         *  1 -->[2], 
         * 
         */

        return store;
    }    //find 6 nearest nodes end

    function findNearestDistanceToEndNodes(x, y){
        let distance = Math.sqrt(Math.pow((x2 - x),2) + Math.pow((y2 - y),2));
        return distance;
    }    //find 6 nearest nodes end

    if (x2 < x1 && y2 < y1) {quadrant = 1} 
    if (x2 > x1 && y2 < y1) {quadrant = 2} 
    if (x2 > x1 && y2 > y1) {quadrant = 3} 
    if (x2 < x1 && y2 > y1) {quadrant = 4} 

    if (x2 == x1 && y2 > y1) {quadrant = 43} 
    if (x2 == x1 && y2 < y1) {quadrant = 12} 
    if (x2 > x1 && y2 == y1) {quadrant = 23} 
    if (x2 < x1 && y2 == y1) {quadrant = 14} 

    console.log(quadrant);

    switch (type1){
        case "hexagon":
            if (quadrant == 1){
                switch (size1){
                    case 2:
                        x1 = nodesStart[0].x;
                        y1 = nodesStart[0].y;
                        gridArray.push({"id" : nodesStart[0].id});
                        console.log("size2_q1");
                        break;
                    case 3:    
                        x1 = nodesStart[1].x;
                        y1 = nodesStart[1].y;
                        gridArray.push({"id" : nodesStart[1].id});
                        break;
                    default:
                        x1 = nodesStart[1].x;
                        y1 = nodesStart[1].y;
                        gridArray.push({"id" : nodesStart[1].id});
                }
            }

            if (quadrant == 2){
                    switch (size1){
                        case 2:
                            x1 = nodesStart[1].x;
                            y1 = nodesStart[1].y;
                            gridArray.push({"id" : nodesStart[1].id});
                            break;
                        case 3:    
                            x1 = nodesStart[2].x;
                            y1 = nodesStart[2].y;
                            gridArray.push({"id" : nodesStart[2].id});
                            break;
                        default:
                            x1 = nodesStart[2].x;
                            y1 = nodesStart[2].y;
                            gridArray.push({"id" : nodesStart[2].id});
                    }
            }

            if (quadrant == 3){
                switch (size1){
                    case 2:
                        x1 = nodesStart[3].x;
                        y1 = nodesStart[3].y;
                        gridArray.push({"id" : nodesStart[3].id});
                        break;
                    case 3:    
                        x1 = nodesStart[4].x;
                        y1 = nodesStart[4].y;
                        gridArray.push({"id" : nodesStart[4].id});
                        break;
                    default:
                        x1 = nodesStart[4].x;
                        y1 = nodesStart[4].y;
                        gridArray.push({"id" : nodesStart[4].id});
                }
            }
            if (quadrant == 4){
                switch (size1){
                    case 2:
                        x1 = nodesStart[4].x;
                        y1 = nodesStart[4].y;
                        gridArray.push({"id" : nodesStart[4].id});
                        break;
                    case 3:    
                        x1 = nodesStart[3].x;
                        y1 = nodesStart[3].y;
                        gridArray.push({"id" : nodesStart[3].id});
                        break;
                    default:
                        x1 = nodesStart[3].x;
                        y1 = nodesStart[3].y;
                        gridArray.push({"id" : nodesStart[3].id});
                }
            }

            if (quadrant == 12){
                switch (size1){
                    case 2:
                        x1 = nodesStart[0].x;
                        y1 = nodesStart[0].y;
                        gridArray.push({"id" : nodesStart[0].id});
                        break;
                    case 3:    
                        x1 = nodesStart[0].x;
                        y1 = nodesStart[0].y;
                        gridArray.push({"id" : nodesStart[0].id});
                        break;
                    default:
                        x1 = nodesStart[0].x;
                        y1 = nodesStart[0].y;
                        gridArray.push({"id" : nodesStart[0].id});
                }
            }

            if (quadrant == 43){
                switch (size1){
                    case 2:
                        x1 = nodesStart[5].x;
                        y1 = nodesStart[5].y;
                        gridArray.push({"id" : nodesStart[5].id});
                        break;
                    case 3:    
                        x1 = nodesStart[5].x;
                        y1 = nodesStart[5].y;
                        gridArray.push({"id" : nodesStart[5].id});
                        break;
                    default:
                        x1 = nodesStart[5].x;
                        y1 = nodesStart[5].y;
                        gridArray.push({"id" : nodesStart[5].id});
                }
            }

            if (quadrant == 14){
                switch (size1){
                    case 2:
                        x1 = nodesStart[2].x;
                        y1 = nodesStart[2].y;
                        gridArray.push({"id" : nodesStart[2].id});
                        break;
                    case 3:    
                    x1 = nodesStart[1].x;
                    y1 = nodesStart[1].y;
                    gridArray.push({"id" : nodesStart[1].id});
                        break;
                    default:
                        x1 = nodesStart[1].x;
                        y1 = nodesStart[1].y;
                        gridArray.push({"id" : nodesStart[1].id});
                }
            }

            if (quadrant == 23){
                switch (size1){
                    case 2:
                        x1 = nodesStart[3].x;
                        y1 = nodesStart[3].y;
                        gridArray.push({"id" : nodesStart[3].id});
                        break;
                    case 3:    
                    x1 = nodesStart[2].x;
                    y1 = nodesStart[2].y;
                    gridArray.push({"id" : nodesStart[2].id});
                        break;
                    default:
                        x1 = nodesStart[2].x;
                        y1 = nodesStart[2].y;
                        gridArray.push({"id" : nodesStart[2].id});
                }
            }
            break;

        case "triangleup":
            if (quadrant == 1){
                x1 = nodesStart[0].x;
                y1 = nodesStart[0].y;
                gridArray.push({"id" : nodesStart[0].id});
            }

            if (quadrant == 2){
                x1 = nodesStart[0].x;
                y1 = nodesStart[0].y;
                gridArray.push({"id" : nodesStart[0].id});
            }

            if (quadrant == 3){
                x1 = nodesStart[4].x;
                y1 = nodesStart[4].y;
                gridArray.push({"id" : nodesStart[4].id});
            }

            break;

            default:
            if (quadrant == 1){
                x1 = nodesStart[1].x;
                y1 = nodesStart[1].y;
                gridArray.push({"id" : nodesStart[1].id});
            }

            if (quadrant == 2){
                x1 = nodesStart[2].x;
                y1 = nodesStart[2].y;
                gridArray.push({"id" : nodesStart[2].id});
            }

            if (quadrant == 3){
                x1 = nodesStart[4].x;
                y1 = nodesStart[4].y;
                gridArray.push({"id" : nodesStart[4].id});
            }
            if (quadrant == 4){
                x1 = nodesStart[3].x;
                y1 = nodesStart[3].y;
                gridArray.push({"id" : nodesStart[3].id});
            }

            if (quadrant == 12){
                x1 = nodesStart[0].x;
                y1 = nodesStart[0].y;
                gridArray.push({"id" : nodesStart[0].id});
            }

            if (quadrant == 43){
                x1 = nodesStart[5].x;
                y1 = nodesStart[5].y;
                gridArray.push({"id" : nodesStart[5].id});
            }

            if (quadrant == 14){
                x1 = nodesStart[1].x;
                y1 = nodesStart[1].y;
                gridArray.push({"id" : nodesStart[1].id});
            }

            if (quadrant == 23){
                x1 = nodesStart[2].x;
                y1 = nodesStart[2].y;
                gridArray.push({"id" : nodesStart[2].id});
            }

    }

    switch (type2){
        case "hexagon":
            if (quadrant == 1){
                switch (size2){
                    case 2:
                        x2 = nodesEnd[5].x;
                        y2 = nodesEnd[5].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[4].x;
                        y2 = nodesEnd[4].y;
                        break;
                    default:
                        x2 = nodesEnd[4].x;
                        y2 = nodesEnd[4].y;
                }
            }
            if (quadrant == 2){
    
                switch (size2){
                    case 2:
                        x2 = nodesEnd[4].x;
                        y2 = nodesEnd[4].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[3].x;
                        y2 = nodesEnd[3].y;
                        break;
                    default:
                        x2 = nodesEnd[3].x;
                        y2 = nodesEnd[3].y;
                }

            }
            if (quadrant == 3){
        
                switch (size2){
                    case 2:
                        x2 = nodesEnd[0].x;
                        y2 = nodesEnd[0].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[1].x;
                        y2 = nodesEnd[1].y;
                        break;
                    default:
                        x2 = nodesEnd[1].x;
                        y2 = nodesEnd[1].y;
                }
            }
            if (quadrant == 4){
               
                switch (size2){
                    case 2:
                        x2 = nodesEnd[1].x;
                        y2 = nodesEnd[1].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[2].x;
                        y2 = nodesEnd[2].y;
                        break;
                    default:
                        x2 = nodesEnd[2].x;
                        y2 = nodesEnd[2].y;
                }
            }
            if (quadrant == 12){
            
                switch (size2){
                    case 2:
                        x2 = nodesEnd[4].x;
                        y2 = nodesEnd[4].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[5].x;
                        y2 = nodesEnd[5].y;
                        break;
                    default:
                        x2 = nodesEnd[5].x;
                        y2 = nodesEnd[5].y;
                }
            }
            if (quadrant == 43){
            
                switch (size2){
                    case 2:
                        x2 = nodesEnd[0].x;
                        y2 = nodesEnd[0].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[0].x;
                        y2 = nodesEnd[0].y;
                        break;
                    default:
                        x2 = nodesEnd[0].x;
                        y2 = nodesEnd[0].y;
                }
            }
            if (quadrant == 14){
                switch (size2){
                    case 2:
                        x2 = nodesEnd[3].x;
                        y2 = nodesEnd[3].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[2].x;
                        y2 = nodesEnd[2].y;
                        break;
                    default:
                        x2 = nodesEnd[2].x;
                        y2 = nodesEnd[2].y;
                }
            }
            if (quadrant == 23){
                switch (size2){
                    case 2:
                        x2 = nodesEnd[2].x;
                        y2 = nodesEnd[2].y;
                        break;
                    case 3:    
                        x2 = nodesEnd[1].x;
                        y2 = nodesEnd[1].y;
                        break;
                    default:
                        x2 = nodesEnd[1].x;
                        y2 = nodesEnd[1].y;
                }
            }
            break;

            case "triangleup":
                if (quadrant == 1){
                    x2 = nodesEnd[4].x;
                    y2 = nodesEnd[4].y;
                }
                if (quadrant == 2){
                    x2 = nodesEnd[3].x;
                    y2 = nodesEnd[3].y;
                }
                if (quadrant == 3){
                    x2 = nodesEnd[0].x;
                    y2 = nodesEnd[0].y;
                }
                if (quadrant == 4){
                    x2 = nodesEnd[0].x;
                    y2 = nodesEnd[0].y;
                }
                if (quadrant == 12){
                    x2 = nodesEnd[3].x;
                    y2 = nodesEnd[3].y;
                }
                if (quadrant == 43){
                    x2 = nodesEnd[0].x;
                    y2 = nodesEnd[0].y;
                }
                if (quadrant == 14){
                    x2 = nodesEnd[4].x;
                    y2 = nodesEnd[4].y;
                }
                if (quadrant == 23){
                    x2 = nodesEnd[3].x;
                    y2 = nodesEnd[3].y;
                }
                break;

            default:
                if (quadrant == 1){
                    x2 = nodesEnd[5].x;
                    y2 = nodesEnd[5].y;
                }
                if (quadrant == 2){
                    x2 = nodesEnd[3].x;
                    y2 = nodesEnd[3].y;
                }
                if (quadrant == 3){
                    x2 = nodesEnd[1].x;
                    y2 = nodesEnd[1].y;
                }
                if (quadrant == 4){
                    x2 = nodesEnd[2].x;
                    y2 = nodesEnd[2].y;
                }
                if (quadrant == 12){
                    x2 = nodesEnd[5].x;
                    y2 = nodesEnd[5].y;
                }
                if (quadrant == 43){
                    x2 = nodesEnd[0].x;
                    y2 = nodesEnd[0].y;
                }
                if (quadrant == 14){
                    x2 = nodesEnd[2].x;
                    y2 = nodesEnd[2].y;
                }
                if (quadrant == 23){
                    x2 = nodesEnd[1].x;
                    y2 = nodesEnd[1].y;
                }
            
    }


        //firt cycle 
        let xPath = x1;
        let yPath = y1;


        let distanceFromEnd = [] 


        //repeat until the nodes is end nodes
        do {            
            // 1. find 3 nearest nodes
            let nodesPath = findNodes(xPath,yPath,4);

            // 2. get distance from end node

            nodesPath.forEach(item => {
                let distance = findNearestDistanceToEndNodes(item.x, item.y);
                distanceFromEnd.push({
                    "id" : item.id,
                    "x" : item.x,
                    "y" : item.y,
                    "distance": distance
                })
            })  
            distanceFromEnd.sort(({"distance":a}, {"distance":b}) => a-b);
        
            gridArray.push(
                {
                    "id" : distanceFromEnd[0].id
                }
            )
            
            xPath = distanceFromEnd[0].x;
            yPath = distanceFromEnd[0].y;

        } while ( (distanceFromEnd[0].x !== x2) || (distanceFromEnd[0].y !== y2));

    return(gridArray);

}

export default getPath;
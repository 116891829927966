import React, { Component } from 'react';

class ModalInfo extends Component {

    constructor(props){
        super();
    }

    render() {
        return(
        <div id="modalInfo" className="modal">
            <div className="modal-body">
                <div className="modal-header">
                    <h2>{this.props.headline}</h2>
                    <button className="btn--close" onClick={() => this.props.handleCloseModal("modalInfo")} >X</button>
                </div>  
                <p>{this.props.text}</p>

                <div className="modal-btns">
                    <button className="abort" onClick={(e) => this.props.handleSelectRoleAbort(e)}>NO</button>
                    <button className="accept" onClick={(e) => this.props.handleSelectRoleChangeRun(e)}>YES</button>

                </div>
            </div>
        </div>
        )
    }
}

export default ModalInfo;  
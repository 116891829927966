const getConnectedNodes = function(edges){
  
    let edgesFinal = [];
    let edgesHelper = [];



    edges.forEach(item=>{

        //Get other edges without this edge
        let edgesFilter = edges.filter(x => x.id !=item.id)

        item.edges.forEach(inner => {
            edgesFilter.forEach(inner2 => {
                inner2.edges.forEach(inner3 =>{
                    if (inner3 == inner){

                        edgesHelper.push({
                            "from" : item.id,
                            "to" : inner2.id,
                            "sum" : item.id + inner2.id
                        })
                    }
                })
            })

        })
    })


        //delete duplicity edges in col sum
        const arrUniq = [...new Map(edgesHelper.map(v => [v.sum, v])).values()]


        //create final lists
        arrUniq.forEach(a=>{
            edgesFinal.push({
                "from" : a.from,
                "to" : a.to,
                "size" : 3
            })
        })



    
    return edgesFinal;




}

export default getConnectedNodes;
import React, { Component } from "react";
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network'
// todo: tento soubor prosim rozdelit na mensi logicke celky, aby to bylo prehlednejsi
//component
import Menu1 from './Menu/Menu1';
import Menu2 from './Menu/Menu2';
import getPath from './GetPath/GetPath';
import getConnectedNodes from './Edges/GetConnectedNodes';
import { updateFile, renameFile, createNewFile, getUserFile, getUserInfo, saveCanvas, logOutUser, importJson, viewImportedData } from './ApiServices/ApiServices';
import { edgesAdd } from "./Edges/Edges";
import ModalTitle from "./Modals/ModalTitle";
import ModalDialog from "./Modals/ModalDialog";
import ModalFile from "./Modals/ModalFile";
import ModalFileMetila from "./Modals/ModalFileMetila";
import ModalSaveCanvas from "./Modals/ModalSaveCanvas";
import ModalSaveDialog from "./Modals/ModalSaveDialog";
import ModalSaveCanvasAs from "./Modals/ModalSaveCanvasAs";
import ModalDeleteEdge from "./Modals/ModalDeleteEdge";
import ModalInfo from "./Modals/ModalInfo";
import {getGrid} from "./Grid/Grid";
import {activeParentHexa} from "./Grid/Canvas";
import {createFlowNodes} from "./Nodes/CreateFlowNodes";
import {getCenters} from "./Grid/Centers";
import { GetCentersForEdges } from "./Edges/GetCentersForEdges";
import { options } from './OptionsVis/OptionsVis';
import { getNodeInfo, visSelectNodeInfo, getNearestCentersNodes, initCanvas } from "./VisNetwork/VisNetworkEvent";
import { setEnvironment } from "./VisNetwork/Environment";

import { getDistanceBetweenNodes } from "./Nodes/Nodes";


var width = 2700;
var height = 2400;
let container;
let canvas;

var grid = true;
var urlApi = "https://msim.metilachat.cz/admx/";

//var urlApi = "https://apimetix.com/admx/"

let addedEdges = [{}];
let masterEdge = [];
const edges = new DataSet([{}]);
var nodesJsonData = getGrid();

let positionCenterFiltered = getCenters(nodesJsonData); //generate parent and child centers

class ZoomAndLevels extends Component {
    constructor() {
        super();
        this.state = {
            level: 1,
            scale: 1,
            dataSet: new DataSet(),
            ctx: undefined,
            activeBtnGrid: true,
            activeMenu: true,
            addEdge: false,
            idJsonFile: "",
            inputTitle: "", //for Add title to Node
            textAreaModalNode: "", //for Nodes clicked
            inputID: "", //for Add title to Node
            otherWays: "",
            modalDialog :  {
                "reaction" : {
                    "2017-05-05":2,
                "2017-05-06":8,
                "2017-05-07":10,
                "2017-05-08":1
                },
                "action" : "Default akce",
                "idEdges" : "idEdges",
                "prevId" : "prevId",
                "nextId" : "nextId"
              },
            edges: [],
            selectedEdges: "",
            edgesSaved: [],
            masterEdge: [],
            centers: [],  //center all nodes in grid
            centerX: 0,
            centerY: 0,
            positionCanvasX: 0,
            positionCanvasY: 0,
            user: [{}],
            nameNewFile: "default_name",
            userFiles: [],
            usersRoles: ["Design", "Model", "Virtual"],
            activeRole: "Design",
            newRole : "",
            consoleLog: "",
            edgesType: 1,
            edgesActive: true,
            stateSaveDialog: false,
            stateMasterEdge: false,
            stateDropNode: false,
            stateEditNode: false, 
            stateMoveCanvas: true,
            stateMoveNodes: true,
            stateImportMetila: false,
            stateImportMindbox: true,
            canvasDesignVersion : 0,
            canvasModelVersion: 0,
            canvasVirtualVersion: 0,
            stateDialog: [],
            idLogFile: "",
            centerWorldX: 800,
            centerWorldY: 200,
            stateSetCenterWorld: false
        };

        this.network = {};
        this.domNode = React.createRef();       
    }

    //ON LOAD REACT COMPONENT
    componentDidMount() {
        const data = {
            nodes: this.state.dataSet,
            edges
        };

        getUserInfo(urlApi, this.callBackUserInfo);
        getUserFile(urlApi, this.callBackUserFile);



        //move generated Centers to state variable of Class
        this.setState({
            centers: positionCenterFiltered.map((x) => {
                return {
                    id: x.id,
                    x: x.x,
                    y: x.y,
                    empty1: true,
                    empty2: true,
                    empty3: true,
                    parentR: true,
                    childR: true,
                    type: x.type
                }
            })
        }, () => {
            //callback function
            this.generateWorld();
            this.generateTemplate(
                ['11120','11119','11090','11061','11034', '11035', '11036','11037','11038', '11062', '11063','11064','11065','11066','11091','11092','11093','11094','11121','11118']
            );
        }
        );

        //END DATA FOR GENERATE PATH EDGES
        this.network = new Network(this.domNode.current, data, options);

        //---------DRAG START------------/

        this.network.on("dragStart", (e) => {

            if (this.state.stateMoveNodes == false){
                this.network.unselectAll();
            } 

            let nodeInfo = visSelectNodeInfo(e.nodes[0],nodesJsonData);
            let connectedEdges = this.network.getConnectedEdges(nodeInfo.id);
            if (connectedEdges){
                connectedEdges.forEach(item => {
                    edges.update([{id: item, hidden: true}]);
                });
            }
       
            //delete edges between nodes - newPath

            this.state.edgesSaved.forEach(item=>{
                if (item.idStart == nodeInfo.id || item.idEnd == nodeInfo.id){
                    item.path.forEach(path =>{
                        edges.remove(path.visId[path.visId.length-1]); 
                        //look out, one Node can have more edges. Lasted is winner.
                        nodesJsonData[path.id-1].visible = false
                    })
                }
            })

            //disable move with NODES in group: border, ...
            if (nodeInfo.group === "border" || nodeInfo.group === "border-main" || nodeInfo.group === "grid" || nodeInfo.group === "grid-parent"){
                this.network.unselectAll();
            }

            if (nodeInfo.id !== undefined){
                let nodesGroupSelection = [];
                //pokud vyberu jeden hexagon z ruzice, tak se budou hybat vsechny
                if (nodeInfo.group !== "grid" && nodeInfo.group !== "grid-parent" && typeof nodeInfo.group != "undefined" && nodeInfo.group.includes('turtle')) {
                    const filteredNodes = nodesJsonData.filter(x => x.group === nodeInfo.group)
                    const groups = filteredNodes.map(item => {
                        nodesGroupSelection.push(item.id);
                    });

                    //select other nodes with same group
                    this.network.setSelection({
                        nodes: nodesGroupSelection,
                    });
                }
                //change state centered nodes
                if (nodeInfo.type === "rosetteParentMain" || nodeInfo.type === "rosetteParentChild"){      
                    nodesGroupSelection.forEach( item => {
                        let nodex = nodesJsonData[item-1].x;
                        let nodey = nodesJsonData[item-1].y;

                        //overlay, if count > 2 then is overlay
                        let overlay = nodesJsonData.filter(item => item.x === nodex && item.y === nodey);

                        //change <3 --> <4 border hexagon
                        if (overlay.length < 4) {
                            this.setState( state => {
                                return{
                                    centers: state.centers.map(item => 
                                    (item.type === "parent" && item.x ===  nodex && item.y === nodey) ? ({...item, parentR: true}) : ({...item})
                                    )
                                }
                            });  
                        }
                    })
                }

                if (nodeInfo.type === "rosetteChildMain" || nodeInfo.type === "rosetteChildChild"){  
                    nodesGroupSelection.forEach( item => {
                        let nodex = nodesJsonData[item-1].x;
                        let nodey = nodesJsonData[item-1].y;

                        //overlay, if count > 2 then is overlay
                        let overlay = nodesJsonData.filter(item => item.x === nodex && item.y === nodey);

                        if (overlay.length < 3) {
                            this.setState( state => {
                                return{
                                    centers: state.centers.map(item => 
                                    (item.type === "child" && item.x ===  nodex && item.y === nodey) ? ({...item, childR: true}) : ({...item})
                                    )
                                }
                            });  
                        }
                    })
                }

                if (nodeInfo.type === "importedJsonData" || nodeInfo.type === "dropElement") {
                    this.setState( state => {
                        return{
                            centers: state.centers.map(item => 
                            (item.x === nodeInfo.x && item.y === nodeInfo.y) ? 
                            (nodeInfo.size == 1 && item.type === "child") ?
                            ({...item, empty1: true}) : 
                            (nodeInfo.size == 2 && item.type === "child") ?
                            ({...item, empty2: true}) :
                            (nodeInfo.size == 3 && item.type === "parent") ?
                            ({...item, empty3: true}) :
                            ({...item}) : ({...item})
                            )
                        }
                    });
                }   
            }


        });//DragSTART

        this.network.on("dragEnd", (e) => {
            let centerCanvas;
            
            //move with canvas --> set new position od canvas


            if (e.nodes[0] == undefined){   

                if (this.state.stateMoveNodes === true){
                    let deltaX = e.event.deltaX;
                    let deltaY = e.event.deltaY;

                    //move with canvas
                   centerCanvas = {
                       x: -deltaX,
                       y: -deltaY
                   };
                }
                
            }

            //test if not move with canvas
            if (e.nodes[0] !== undefined){
                    let nodeInfo = visSelectNodeInfo(e.nodes[0],nodesJsonData);
                    const xa = e.pointer.canvas.x;
                    const ya = e.pointer.canvas.y;
             
                    let groups = nodesJsonData.filter(item => item.group === nodeInfo.group);
                    let connectedEdges = this.network.getConnectedEdges(nodeInfo.id);
                    connectedEdges.forEach(item => {
                        edges.update([{id: item, hidden: false}]);
                    });

                    centerCanvas = {x: 0,y: 0};

                    // delete centers where is node
                    //NEW code which respect SIZE NODE
                    let filteredCenterNew;

                    if (nodeInfo.type === "importedJsonData" || nodeInfo.type === "dropElement") {
                        nodeInfo.size == 1 ? filteredCenterNew = this.state.centers.filter(center => center.empty1 === true && center.type === "child") :
                        nodeInfo.size == 2 ? filteredCenterNew = this.state.centers.filter(center => center.empty2 === true && center.type === "child") :
                        nodeInfo.size == 3 ? filteredCenterNew = this.state.centers.filter(center => center.empty3 === true && center.type === "parent") : console.log("NODE witout size parametr!")
                    }

                    if (nodeInfo.type === "rosetteParentMain" || nodeInfo.type === "rosetteParentChild"){
                        filteredCenterNew = this.state.centers.filter(center => center.parentR === true && center.type === "parent");
                    }

                    if (nodeInfo.type === "rosetteChildMain" || nodeInfo.type === "rosetteChildChild"){
                        filteredCenterNew = this.state.centers.filter(center => center.childR === true && center.type === "child");
                    }

                    //get distances center from node
                    const nodesDistance = filteredCenterNew.map(item => {
                            //count of distance point in 2d
                            function distanceXY (){
                                return Math.sqrt(Math.pow((item.x - xa),2) + Math.pow((item.y - ya),2));
                            }                    
                            return {
                                id : item.id,
                                x : item.x,
                                y : item.y,
                                distance : distanceXY()
                            }
                    })

                    // first node will be hero
                    nodesDistance.sort((a,b) => parseFloat(a.distance) - parseFloat(b.distance));

                    //move node to center
                    this.network.moveNode(e.nodes[0], nodesDistance[0].x, nodesDistance[0].y);
                    // move other node from groups to NODE
                    //centers rosette
                    if (nodeInfo.type === "rosetteParentMain"){
                        this.setState( state => {
                            return{
                                centers: state.centers.map(item => 
                                (item.type === "parent" && item.x === nodesDistance[0].x && item.y === nodesDistance[0].y) ? ({...item, parentR: false}) : ({...item})
                                )
                            }
                        });

                        let centers = this.getCentresNode(nodesDistance[0].x, nodesDistance[0].y, 3, "parent");
                        //center Turtle
               
                        for (let i = 1; i < 7; i++) {
                            //move node to new x,y
                            this.network.moveNode(groups[i].id, centers[i].x,centers[i].y);
                            // update data in nodesJsonData
                            nodesJsonData[groups[i].id-1].x = centers[i].x;
                            nodesJsonData[groups[i].id-1].y = centers[i].y;

                            this.setState( state => {
                                return{
                                    centers: state.centers.map(item => 
                                    (item.type === "parent" && item.x === centers[i].x && item.y === centers[i].y) ? ({...item, parentR: false}) : ({...item})
                                    )
                                }
                            });

                            // if on drop position is shield, then set visible on false and set center as empty
                            nodesJsonData.forEach( item => {
                                if (item.group === "border" && item.visible === true && item.x === centers[i].x && item.y === centers[i].y && item.type === "parent"){
                                    //item.visible = false;
                                    item.image = "img/hexagon-size-selected.svg";
                                    this.setState( state => {
                                        return{
                                            centers: state.centers.map(item => 
                                                (item.x === centers[i].x && item.y === centers[i].y && item.type === "parent" ) ? 
                                                ({...item, empty1: true, parentR:true, empty3: true, empty2: true}) : ({...item})
                                            )}
                                    });
                                }
                            })
                        }
                    }//end if

                    if (nodeInfo.type === "rosetteChildMain"){
                        this.setState( state => {
                            return{
                                centers: state.centers.map(item => 
                                (item.type === "child" && item.x === nodesDistance[0].x && item.y === nodesDistance[0].y) ? ({...item, childR: false}) : ({...item})
                                )
                            }
                        });

                        let centers = this.getCentresNode(nodesDistance[0].x, nodesDistance[0].y, 2, "child");
               
                        //add 6 nodes to turtle --> TO DO...stred techto 6 node musi byt pro ostatni nody childs tutle not empty
                        for (let i = 1; i < 7; i++) {
                            //move node to new x,y
                            this.network.moveNode(groups[i].id, centers[i].x,centers[i].y);
                            // update data in nodesJsonData
                            nodesJsonData[groups[i].id-1].x = centers[i].x;
                            nodesJsonData[groups[i].id-1].y = centers[i].y;
                            
                            //set Center as false
                            this.setState( state => {
                                return{
                                    centers: state.centers.map(item => 
                                    (item.type === "child" && item.x === centers[i].x && item.y === centers[i].y) ? ({...item, childR: false}) : ({...item})
                                    )
                                }
                            });
                        }
                    }

                     //centers Imported and DragAndDrop node
                    if (nodeInfo.type === "importedJsonData" || nodeInfo.type === "dropElement") {
                        this.setState( state => {
                            return{
                                centers: state.centers.map(item => 
                                (item.x === nodesDistance[0].x && item.y === nodesDistance[0].y) ? 
                                (nodeInfo.size == 1 && item.type === "child") ?
                                ({...item, empty1: false}) : 
                                (nodeInfo.size == 2 && item.type === "child") ?
                                ({...item, empty2: false}) :
                                (nodeInfo.size == 3 && item.type === "parent") ?
                                ({...item, empty3: false}) :
                                ({...item}) : ({...item})
                                )
                            }
                        })
                    }

                    //update nodeJson position of node
                    nodesJsonData[nodeInfo.id-1].x = nodesDistance[0].x;
                    nodesJsonData[nodeInfo.id-1].y = nodesDistance[0].y;

            //Ma node EDGES? --> Regenerate PATH
            this.state.edgesSaved.forEach(item=>{
                if ((item.idStart == nodeInfo.id || item.idEnd == nodeInfo.id) && item.state !== "delete"){

                    let node1 = item.idEnd;
                    let node2 = item.idStart;

                    node1 = getNodeInfo(item.idEnd, nodesJsonData);
                    node2 = getNodeInfo(item.idStart, nodesJsonData);

                     let arrayOfEdges = [];
                     arrayOfEdges = getPath(
                         parseInt(node2.node.x),
                         parseInt(node2.node.y),  
                         parseInt(node1.node.x), 
                         parseInt(node1.node.y), 
                         node2.node.size, 
                         node1.node.size,
                         node2.node.image, 
                         node1.node.image,
                         GetCentersForEdges(nodesJsonData, positionCenterFiltered, parseInt(node1.node.x), parseInt(node1.node.y), parseInt(node2.node.x),parseInt(node2.node.y), node1.node.size)
                     );

                     arrayOfEdges.forEach(item => {
                        nodesJsonData[item.id-1].visible = true;
                        //refres dome
                    }) 

                    this.filterByLevel(this.state.level);


                     // this path is not actual --> tag to delete
                    item.state = "delete";
                    this.generatePathFromEdges(node1.node.id, node2.node.id, arrayOfEdges, node1.node.size);
                }
            })

        }//is node


        this.filterByLevel(this.state.level, centerCanvas);

        });//DragEND

        this.network.on("doubleClick", (e) =>{

            if (this.state.stateEditNode === true){
                if (e.nodes[0]){
                    let nodeInfo = visSelectNodeInfo(e.nodes[0],nodesJsonData);
                    if (nodeInfo && nodeInfo.group == "dialogElement"){
                        this.handleOpenModalDialog("modalDialog");


                        //select way of edges (modal dialog)

                        this.selectWayOfNode(this.state.inputID)




                    }else if(nodeInfo){
                        this.handleOpenModal("modalTitle");
                    }
                }
            }

        });



        this.network.on("selectEdge", (e) =>{
            this.setState({selectedEdges: e.edges})
        });

        //EVENT FOR SELECT NODE
        this.network.on("selectNode", (e) => {

            //set new center for import data from MindBox
            if (this.state.stateSetCenterWorld){
                this.setState({centerWorldX : e.event.center.x});
                this.setState({centerWorldY : e.event.center.y});
                this.setState({stateSetCenterWorld : false});
                this.handleOpenModal("modalFile");
                return;
            }

            let nodeInfo = visSelectNodeInfo(e.nodes[0],nodesJsonData);        

            //resetState
            this.setState({turtleDistances: []});
            this.setState({inputTitle: nodeInfo.label});
            this.setState({textAreaModalNode: nodeInfo.textarea});
            this.setState({inputID: nodeInfo.id});


            // set dialog
            this.state.stateDialog.forEach(item =>{
                if (item.id == nodeInfo.id){
                    this.setState({
                        modalDialog:  {
                            "reaction" : item.reaction,
                            "action" : item.action,
                            "idEdges" : item.idedge,
                            "prevId" : item.prevId,
                            "nextId" : item.nextId
                          }
                    });
                }
            })

            


            if (nodeInfo.group === "border"){
                
                this.network.deleteSelected();
                //center canvas: offset set to 0
                let centerCanvas = {x: 0,y: 0};

                nodesJsonData[nodeInfo.id-1].image = 'img/hexagon-size-selected.svg';

                let x1 = nodesJsonData[nodeInfo.id-1].x;
                let y1 = nodesJsonData[nodeInfo.id-1].y;

                this.setState( state => {
                    return{
                        centers: state.centers.map(item => 
                            (item.x === x1 && item.y === y1 && item.type === "parent" ) ? 
                            ({...item, empty1: true, parentR:true, empty3: true, empty2: true}) : ({...item})
                        )}
                });
                this.filterByLevel(this.state.level,centerCanvas);
            }

            this.setState( {
                consoleLog: ("Clicked on Node: " + "ID NODE: " + nodeInfo.id + ", GROUP: " + nodeInfo.group + ", TITLE: " + nodeInfo.title + ", TYPE: " + nodeInfo.type + ", X: " + nodesJsonData[nodeInfo.id-1].x + " Y: " + nodesJsonData[nodeInfo.id-1].y)
            });

            this.logData();

            if (this.state.stateMasterEdge){
                masterEdge.push(nodeInfo.id);

                if (masterEdge.length >= 2) {

                    let distance = getDistanceBetweenNodes(masterEdge[masterEdge.length - 2], masterEdge[masterEdge.length - 1], nodesJsonData)

                 
                     edges.add([
                         { from: masterEdge[masterEdge.length - 2], to: masterEdge[masterEdge.length - 1],      
                             arrows:{
                             from:{
                                 src: "/img/master-edge.jpg",
                                 imageWidth: 12,
                                 imageHeight: distance - 90,
                             },   
                             to:{
                                 src: "/img/master-edge.jpg",
                                 imageWidth: 12,
                                 imageHeight: distance - 90,
                             },          
                         },},
                      ]);

                     this.setState({masterEdge: masterEdge});

                     //add id nodes for save canvas to state variable
                }
            }

            // Add edges is turn on
            if (this.state.addEdge) {
                //get x and y position Node
                let filteredAddEdge = nodesJsonData.filter(x => x.id == nodeInfo.id);
                addedEdges.push({
                    "node": {
                        "id" : nodeInfo.id,
                        "size" : filteredAddEdge[0].size,
                        "shape" : filteredAddEdge[0].shape,
                        "image" : filteredAddEdge[0].image,
                        "group" : filteredAddEdge[0].group,
                        "x" : filteredAddEdge[0].x,
                        "y" : filteredAddEdge[0].y,
                    },
                    "x" : filteredAddEdge[0].x,
                    "y" : filteredAddEdge[0].y,
                });
                
                //view edges
                if (addedEdges.length > 2) {
                    let node1 = addedEdges[addedEdges.length-1];
                    let node2 = addedEdges[addedEdges.length-2];
                    //primary edges from vis library
                    //get path between two nodes
                    let arrayOfEdges = [];
                    arrayOfEdges = getPath(
                        parseInt(node2.node.x),
                        parseInt(node2.node.y),  
                        parseInt(node1.node.x), 
                        parseInt(node1.node.y), 
                        node2.node.size, 
                        node1.node.size,
                        node2.node.image, 
                        node1.node.image,
                        GetCentersForEdges(nodesJsonData, positionCenterFiltered, parseInt(node1.node.x), parseInt(node1.node.y), parseInt(node2.node.x),parseInt(node2.node.y), node1.node.size)
                        );
                    arrayOfEdges.forEach(item => {
                        nodesJsonData[item.id-1].visible = true;
                    }) 
                    //refresh data!
                    this.filterByLevel(this.state.level);
                    this.generatePathFromEdges(node1.node.id, node2.node.id, arrayOfEdges, node1.node.size);

                    this.setState( {consoleLog: "Edges added..."});  
                    this.setState({stateDropNode: true})
                    this.setState({stateMoveNodes: true})
                    this.setState({stateMoveCanvas: true})

                    this.filterByLevel(this.state.level);
                  
                }
            }//end of AddEdge

        });//SelectNode

        container = this.network.canvas.frame;
        canvas = this.network.canvas.frame.canvas;

        // Set start position [0,0] (default Vis initialization is from center)
            this.setState({ctx: canvas.getContext("2d")})
            container.oncontextmenu = function () {
                return false;
            };
    }



    // For custom title - use for hover Ndode
     htmlTitle(html) {
        const container = document.createElement("div");
        container.classList = 'parentTitle';
        container.innerHTML = html;
        return container;
      }

    generatePathFromEdges(node1, node2, arrayOfEdges, sizeNodes){

        var edgesWithVisId = [];
        let parEdges = edgesAdd(sizeNodes, this.state.edgesType);

        //the first element
        let nodeInfo = visSelectNodeInfo(arrayOfEdges[0].id,nodesJsonData);
        edgesWithVisId.push({
            "id" : arrayOfEdges[0].id,
            "x" : nodeInfo.x,
            "y" : nodeInfo.y,
            "visId" : this.network.getConnectedEdges(arrayOfEdges[0].id),
        })

        for (let i = 0; i < arrayOfEdges.length - 1; i++) {
            edges.add([
                { from: arrayOfEdges[i+1].id, to: arrayOfEdges[i].id,      
                    arrows:{
                    from:{
                        src: parEdges[0],
                        imageWidth: parEdges[1],
                        imageHeight: parEdges[2],
                    },   
                    to:{
                        src: parEdges[0],
                        imageWidth: parEdges[1],
                        imageHeight: parEdges[2],
                    },          
                },},
             ]);

             this.setState({addEdge: false});

            //hledám center node, který není při inicialiaci visible (Grid.JS) - není v network

            let nodeInfo = visSelectNodeInfo(arrayOfEdges[i+1].id,nodesJsonData);

             edgesWithVisId.push({
                 "id" : arrayOfEdges[i+1].id,
                 "x" : nodeInfo.x,
                 "y" : nodeInfo.y,
                 "visId" : this.network.getConnectedEdges(arrayOfEdges[i+1].id),
             })

        } 

        let newElemnToEdges = {
            "idStart" : node1,
            "idEnd" : node2, 
            "idEdges" : node1 + "_" + node2,
            "sizeEdge" : this.state.edgesType,
            "path" : edgesWithVisId,
            "state" : "ok"
        }

        //insert new path between node1 a node2
        this.setState(prevState => {
            return{
                ...prevState, edgesSaved: [...prevState.edgesSaved, newElemnToEdges ]
            }
        });

        //delete old path with state deleted
        this.setState(prevState => ({edgesSaved: prevState.edgesSaved.filter(item => item.state !== "delete") }));





    };//generatePathFromEdges

    generateCanvasFromHexagon = (start, end, step, group, image, reminder) => {
        for (let i = start; i < end; i = i + step) {
            if ( i % reminder != 0 ) {
                let x1 = this.state.centers[i].x;
                let y1 = this.state.centers[i].y;
                nodesJsonData.push({ 
                    id: nodesJsonData.length+1, 
                    label: '', 
                    shape: 'image', 
                    image: image,
                    x: x1,
                    y: y1,
                    level: 1,
                    size: 3,
                    group: group,
                    title: "",
                    type: "parent",
                    visible: true,
                });

                //set node as not empty
                this.setState( state => {
                    return{
                        centers: state.centers.map(item => 
                            (item.x === x1 && item.y === y1 && item.type === "parent" ) ? 
                            ({...item, empty1: false, parentR:false, empty3: false, empty2: false}) : ({...item})
                    )}
                });
            }//reminder
        }
    };

    generateWorld = () => {
        this.generateCanvasFromHexagon(0, 29, 1, 'border-main', 'img/hexagon-vertical-border.svg', 0);
        this.generateCanvasFromHexagon(29, 880, 29, 'border-main', 'img/hexagon-vertical-border.svg', 0);
        this.generateCanvasFromHexagon(30, 871, 1, 'border', 'img/hexagon-vertical-border.svg', 29);
        //this.filterByLevel(this.state.level);
    }

    filterByLevel(level, centerCanvas) {

                const filteredData = nodesJsonData.filter(x => 
                    x.level === level && 
                    x.visible === true);

                    // grig vypne moznost propojovat nodes size2, grid-parent zase size3 nodes
                const nodes = filteredData.map(item => {
                    let label;
                    let title;
                    let size = item.size;
                    let nodeTitleBg;

                    if (size == 1){nodeTitleBg = "nodetitle--size1"}
                    else if (size == 2){nodeTitleBg = "nodetitle--size2"}
                    else if (size == 3){nodeTitleBg = "nodetitle--size3"}
                    else {nodeTitleBg = "nodetitle--default"}

                    if (item.label) {label = " \n" + item.label + ""}
                    else label = "";

                    if (item.title){
                        title = this.htmlTitle(
                            '<span class="nodetitle '+ nodeTitleBg +'">' + item.label + '</span>'
                        )
                    }
                    else title = ""
                
                    return {
                        id: item.id,
                        label: label,
                        font: { multi: "html", size: 10, color: "#000000"},
                        shape: item.shape,
                        image: item.image,
                        title: title,
                        x:  item.x,
                        y:  item.y,
                        type: item.type,
                        size: this.renderShapeBySize(item),
                        color: {
                            background: item.color,
                            border: "red",
                            highlight: { background: "black", border: "black" },
                            hover:{background: "gold", border: "black"}
                        },
                    }
            })

           // let newPosition = this.network.getViewPosition();
            const data = {nodes,edges};
            this.network.setData(data);

            if (centerCanvas){
                let offsetX = centerCanvas.x;
                let offsetY = centerCanvas.y;
                let newPositionX;
                let newPositionY;

                if (this.state.positionCanvasX + offsetX >=-200 && this.state.positionCanvasX + offsetX <=1350){
                    newPositionX = this.state.positionCanvasX + offsetX;
                } else{ newPositionX = this.state.positionCanvasX}

                if (this.state.positionCanvasY + offsetY >=-200 && this.state.positionCanvasY + offsetY <=1600){
                    newPositionY = this.state.positionCanvasY + offsetY;
                } else{ newPositionY = this.state.positionCanvasY}

                this.setState( {positionCanvasX: newPositionX});
                this.setState( {positionCanvasY: newPositionY});

                this.network.moveTo(
                    {
                        position: {
                            x: this.state.positionCanvasX, 
                            y: this.state.positionCanvasY
                        },
                        offset: { 
                            x: -(width / 2), 
                            y: -(height / 2) 
                        },
                        scale: this.state.scale
                    }
                );
                this.setState( {consoleLog: "Position CANVAS: " + this.state.positionCanvasX + "," + this.state.positionCanvasY});
            }else{
                this.network.moveTo(
                    {
                        position: {
                            x: 0, 
                            y: 0
                        },
                        offset: { 
                            x: -(width / 2), 
                            y: -(height / 2) 
                        },
                        scale: this.state.scale
                    }
                );
            }
    }

    renderShapeBySize(node) {
         switch (node.shape) {
            case 'dot':
                return node.size === 1 ? 2 : node.size === 2 ? 4 : node.size === 3 ? 22 : 4
            case 'image':
                return node.size === 1 ? 20 : node.size === 2 ? 30 : node.size === 3 ? 50 : 20
             default:
                 return 20
         }
    }

    handleAddEdge = (value) =>{

        if ( value == "toggle") {
            value = !this.state.addEdge;

            this.setState(prevState => ({
                stateDropNode: false,
                stateMoveNodes: !prevState.stateMoveNodes,
                stateMoveCanvas: !prevState.stateMoveCanvas
              }));

        }

        this.setState({addEdge: value});
        addedEdges = [{}];

        this.setState( {consoleLog: "Add EDGES: " + value}); 
    }

    handleNewAddAdge = () =>{this.setState({addEdge: true});}

    handleNewEdge = () =>{
        this.setState(prevState => {
            return{
                ...prevState, edgesSaved: [...prevState.edgesSaved, addedEdges]
            }
        });
        this.setState({addEdge: false});
    }

    handleDeleteEdges = () =>{
      //  edges.clear();
      //  addedEdges = [{}];
    }

    handleDeleteEdge = () =>{
        this.handleCloseModal("modalDeleteEdge");
        edges.remove(this.state.selectedEdges[0]);
        this.setState( {selectedEdges: ""}); 
        this.setState( {consoleLog: "Edge " + this.state.selectedEdges[0] + " was removed."}); 
    }

    handleHideEdgeOne = (idEdge) => {
        edges.update([{id: idEdge, hidden: true}]);
    }
  
    handleZoom = (value) => {
        let increment = value === 'IN' ? + 0.2 : -0.2;
        let newScale;

        if ((this.network.getScale() + increment) <= 1){
            newScale = 1;
            this.setState( {consoleLog: "Max Zoom OUT"});
        } else{
            newScale = this.network.getScale() + increment;
            this.setState( {consoleLog: "Zoom " + value + "(" + newScale + ")"});
        }

        const options = {
            scale: (newScale),
            animation: {
                duration: 200,
                easingFunction: "easeInOutQuad"
            }
        };
        this.setState({scale: newScale})
        this.network.moveTo(options)
    }

    handleSetLevel = (x) => {
        this.setState({ level: x });
        this.filterByLevel(x);
    }

    generateTemplate = (idNodes) => {
        idNodes.forEach( item => {
            let nodeX = nodesJsonData[item-1].x;
            let nodeY = nodesJsonData[item-1].y;
            nodesJsonData[item-1].image = "img/hexagon-size-selected.svg";

            this.setState( state => {
                return{
                    centers: state.centers.map(item => 
                        (item.x === nodeX && item.y === nodeY && item.type === "parent" ) ? 
                        ({...item, empty1: true, parentR:true, empty3: true, empty2: true}) : ({...item})
                    )}
            });
        })

        this.filterByLevel(this.state.level);

    }

    selectWayOfNode(id, mEdge){
        
        let mainEdge = "";

        if (mEdge) {
            mainEdge = mEdge;
        } else{
            mainEdge = this.state.modalDialog.idEdges;
        }

        //find all nodes in mainEdge and change image
        this.state.stateDialog.forEach(item=>{
            if (item.idedge == mainEdge){
                if (item.id < id){
                    nodesJsonData[item.id-1].image = "img/circle-size-1-flow-75.svg";
                } else{
                    nodesJsonData[item.id-1].image = "img/circle-size-1-flow-15.svg";
                }
            } else{
                nodesJsonData[item.id-1].image = "img/circle-size-1-flow.svg";
            }
        })

        //selected node
        nodesJsonData[id-1].image = "img/circle-size-1-flow-full.svg";



        this.filterByLevel(this.state.level);



    }

    //import data from server
    handleImportJson = (e, idFile) => {
        e.preventDefault();
        if (!idFile){
            idFile = this.state.idJsonFile;
        }
        console.log("Importuji tento soubor s ID: " + idFile);
        importJson(urlApi, this.callbackImportJson, idFile);
         this.handleCloseModal("modalFile");
    };

    callbackImportJson = (data) => this.viewImportedJson(data);

    viewImportedJson(data){

        let result = viewImportedData(data, nodesJsonData, [this.state.centerWorldX,this.state.centerWorldY])
        nodesJsonData = result[0];
        this.setState( {consoleLog: "Data from API was imported! ID of file: " + this.state.idJsonFile}); 
        this.logData();
        this.handleCenter("groupImportedJsonR");
        this.addEdges(result[1]);
        this.filterByLevel(this.state.level);
    }

    addEdges (data){
        data.forEach(item => {

            let edgesWidth = 2;
            if (item.size){
                edgesWidth = item.size;
            }

            let distance = getDistanceBetweenNodes(item.fromConvert, item.toConvert, nodesJsonData)

            edges.add([
                { from: item.fromConvert, to: item.toConvert,      
                    arrows:{
                    from:{
                        src: "/img/edges-2.jpg",
                        imageWidth: edgesWidth * 3,
                        imageHeight: distance - 90,
                    },   
                    to:{
                        src: "/img/edges-2.jpg",
                        imageWidth: edgesWidth * 3,
                        imageHeight: distance - 90,
                    },          
                },},
             ]);

        })


    }

    callBackUpdateFile = (data) => {
        console.log("Update FILE");
    }

    handleCenter(group) {
        let filteredImportedJson = nodesJsonData.filter(item => item.group === group);
        filteredImportedJson.forEach(item => {
            //get center near Node
            let center = this.getCentreNode(item.x,item.y,item.size, item.type);
            let size = item.size;

            //save new position node
            nodesJsonData[item.id-1].x = center[0];
            nodesJsonData[item.id-1].y = center[1];

            if (size == 2) {
                let activeId = activeParentHexa(center[0], center[1], nodesJsonData);
                this.generateTemplate(activeId);
            }

            //create world under imported data
            nodesJsonData.forEach(item => {
                if (item.x === center[0] && item.y === center[1] && item.group === "border"){
                    this.generateTemplate( [item.id] );
                }
            })

            //change state centered nodes
            this.setState( state => {
                return{
                    centers: state.centers.map(item => 
                     (item.x === center[0] && item.y === center[1]) ? 
                     (size == 1) ?
                     ({...item, empty1: false}) : 
                     (size == 2 && item.type === "child") ?
                     ({...item, empty2: false}) :
                     (size == 3 && item.type === "parent") ?
                     ({...item, empty3: false}) :
                     ({...item}) : ({...item})
                    )
                }
            });
        });  
    }

    //import data from local file
    handleImportFile = (e) => {
          var fr = new FileReader();
          fr.onload = e => { 
            var result = JSON.parse(e.target.result);
            var importedJsonFromFile = result.nodes;
            let minX = [];
            let minY = [];

            //get minX and minY of importedData -> centered imported data
            importedJsonFromFile.forEach(item => {
                minX.push(item.x);
                minY.push(item.y);
            }); 
    
            //set new X and Y to ImportedData
            importedJsonFromFile.forEach(item =>{
                    item.x = item.x - Math.min(...minX) + 90;
                    item.y = item.y - Math.min(...minY) + 90;
            });

            //filter BAD SIZE NODES for example size = 5
            importedJsonFromFile.forEach( item =>{
                if (item.size == 1) {item.size = 1} 
                else if (item.size == 2) {item.size = 2} 
                else if (item.size == 3) {item.size = 3}
                else {item.size = 2};

            });
            this.setState( {consoleLog: importedJsonFromFile});
            this.viewImportedJson(importedJsonFromFile);
          }
        
         fr.readAsText(e.target.files[0]);
    }

    //it is called from ApiServices Compon.
    callBackUserInfo = (arrayOfObjs) => {this.setState({user: arrayOfObjs});};
    callBackUserFile = (arrayOfObjs) => {
        this.setState({userFiles: arrayOfObjs});

        arrayOfObjs.forEach(item => {
            if (item.name == "logfile") this.state.idLogFile = item.id;
        })

        if (arrayOfObjs[0].id){
            this.setState({idJsonFile: arrayOfObjs[0].id});
        }

    };

    //get 7 nearest center
    getCentresNode(x, y, size, type){
        let filteredCenter = this.state.centers.filter(item => item.type === type)
        //get distances center from node
        const nodesDistance = filteredCenter.map(item => {
                function distanceXY (){
                    return Math.sqrt(Math.pow((item.x - x),2) + Math.pow((item.y - y),2));
                }   
                return {
                    id : item.id,
                    x : item.x,
                    y : item.y,
                    distance : distanceXY()
                }
        })

        nodesDistance.sort((a,b) => parseFloat(a.distance) - parseFloat(b.distance))
        let centers = nodesDistance;
        return centers;
    }

    // get one empty center
     getCentreNode(x, y, size, type){
        let center = [];
        let filteredCenter;

        if (type === "parent"){
            filteredCenter = this.state.centers.filter(item => item.parentR === true && item.type === "parent");
        } else if (type === "child"){
            filteredCenter = this.state.centers.filter(item => item.childR === true && item.type === "child");
        } else if (type === "importedJsonData"){
            //only imported data. This active centres
            size == 1 ? filteredCenter = this.state.centers.filter(center => center.type === "child") :
            size == 2 ? filteredCenter = this.state.centers.filter(center => center.type === "child") :
            size == 3 ? filteredCenter = this.state.centers.filter(center => center.type === "parent") : console.log("NODE do not have parametr SIZE")
        }else if (type !== "parent" || type !=="child") {
            size == 1 ? filteredCenter = this.state.centers.filter(center => center.empty1 === true && center.type === "child") :
            size == 2 ? filteredCenter = this.state.centers.filter(center => center.empty2 === true && center.type === "child") :
            size == 3 ? filteredCenter = this.state.centers.filter(center => center.empty3 === true && center.type === "parent") : console.log("NODE do not have parametr SIZE")
        }

        //get distances center from node
        const nodesDistance = filteredCenter.map(item => {
                function distanceXY (){
                    return Math.sqrt(Math.pow((item.x - x),2) + Math.pow((item.y - y),2));
                }   
                return {
                    id : item.id,
                    x : item.x,
                    y : item.y,
                    distance : distanceXY()
                }
        })

        nodesDistance.sort((a,b) => parseFloat(a.distance) - parseFloat(b.distance))
        center[0] = nodesDistance[0].x;
        center[1] = nodesDistance[0].y;
        return center;
    }

    //Turn on menu for add Edges
    handleEdges(){
        let mainNav = document.getElementById("nav-edges");
        mainNav.classList.toggle('opacity-1');
    }

    handleEdgesType = (event) =>{
        let newValue = this.state.edgesType + 1;
        if (newValue == 4) { newValue = 1};
        this.setState( {edgesType: newValue}); 
        this.setState( {consoleLog: "Change EDGES type to: " + newValue}); 
    }

    handleSaveCanvas = () =>{

         let filteredJson = nodesJsonData.filter(function (item) {
             return item.group !=="grid" && item.group !== "grid-parent" && item.type !=="parent";
         });

         let edges = [];
         filteredJson.forEach(item => {
             edges.push({
                 "id" : item.id,
                 "edges" : this.network.getConnectedEdges(item.id)
             })
         })
         saveCanvas(urlApi,filteredJson, this.state.idJsonFile, edges, this.state.edgesSaved, this.callBackSaveCanvas, this.state.masterEdge );
         this.handleCloseModal("modalSaveCanvas");

    }

    callBackSaveCanvas = (data) => {
        if (data.ok == false){
            this.setState({consoleLog: "Error: " + data.statusText});           

        } else{
            this.setState({consoleLog: "Canvas was saved."}); 
            this.logData();          
        }
    }

    handleCreateFile = ( nameOfFile) =>{
        createNewFile(urlApi, this.callBackCreateFile, nameOfFile);
    }


    handleSaveCanvasAs = () => {
        this.handleCloseModal("modalSaveCanvas");
        this.handleOpenModal("modalSaveCanvasAs");
    }

    callBackCreateFile = (data, nameOfFile) => {

         
        if (data.status == "error"){
            this.setState({consoleLog: "Error: " + data.message});           

        } else{
            this.setState({consoleLog: "File was created with ID: " + data.id});           
        }

        this.handleCloseModal("modalSaveCanvasAs");
        //rename FILE

        //change ID actual new file
        this.setState({idJsonFile: data.id});

        let versionCanvas = "";
        let activeRole = this.state.activeRole;
        let nameNewFile = this.state.nameNewFile;

        if (nameOfFile){
            activeRole = nameOfFile;
            nameNewFile = "saved_change_role";
        }

        switch(activeRole) {
            case "Design":
                versionCanvas = this.state.canvasDesignVersion
              break;
            case "Model":
                versionCanvas = this.state.canvasModelVersion
              break;
              case "Virtual":
                versionCanvas = this.state.canvasVirtualVersion
                break;
            default:
              versionCanvas = "1"
          }  

        let nameFile = activeRole + "_" + versionCanvas + "_" + nameNewFile;

        renameFile(urlApi, this.callBackRenameFile, data.id, nameFile);
        this.handleSaveCanvas();

        //update user files in state variable
        getUserFile(urlApi, this.callBackUserFile);
    }

    handleInputNameChange = (event) => {this.setState({nameNewFile: event.target.value});}


    callBackRenameFile = () => { 
        this.setState( {consoleLog: "File was renamed"});
    }

    handleLogout = () =>{
        this.setState( {consoleLog: "Log out."});
        this.logData();
        logOutUser(urlApi);
    }

    //center imported JSON data to GRID
    getCenter = (x, y, size, type) =>{
        let center = this.getCentreNode(x, y, size, type);
        return center;
    }

    //turn off grid
    handleGrid = () => {
        if (grid == true) {
            let gridNodes = nodesJsonData.filter(x => x.group === "grid");
            gridNodes.map(item => {item.level = "turnOffGrid";});
            grid = false;
            this.setState({activeBtnGrid: false});   
            this.setState({consoleLog: "GRID OFF"});           
        } else {
            let gridNodes = nodesJsonData.filter(x => x.level === "turnOffGrid");
            gridNodes.map(item => {item.level = 1;});
            grid = true;
            this.setState({activeBtnGrid: true}); 
            this.setState({consoleLog: "GRID ON"});           
        }
   
        this.filterByLevel(this.state.level);
    }

    handleSelectChange = (event) =>{
        this.setState({idJsonFile: event.target.value});
    }

    handleSelectRoleChange = (event) =>{
        this.handleOpenModal("modalInfo");
        this.setState( {newRole: event.target.value});
    }

    handleSelectRoleAbort = ( ) => {
      this.handleCloseModal("modalInfo");
      let select = document.getElementById("selectRole");
      select.value = this.state.activeRole;

    }

    handleSelectRoleChangeRun = (e) =>{
        let activeRole = this.state.activeRole;
        // change version CANVAS
        switch(activeRole) {
            case "Design":
                this.setState({canvasDesignVersion: this.state.canvasDesignVersion + 1})
                break;
            case "Model":
                this.setState({canvasModelVersion: this.state.canvasModelVersion + 1})
                break;
            case "Virtual":
                this.setState({canvasVirtualVersion: this.state.canvasVirtualVersion + 1})
                break;
          } 

        // add save canvas
        this.handleCreateFile(activeRole);


        this.setState({activeRole: this.state.newRole});
        let result = setEnvironment(this.state.newRole);

        for (let [key, value] of Object.entries(result[0])) {
            this.setState({[key]: value});
        }
    
        this.setState({consoleLog: "Setting role to: " + this.state.newRole});
        this.logData();

        if (this.state.newRole == "Design"){

            this.setState({stateMoveNodes: true})
                //resetEdges START
                this.state.edgesSaved.forEach(item=>{
                    item.path.forEach(path =>{
                        edges.remove(path.visId[path.visId.length-1]); 
                    })
                
                 })

                this.state.edgesSaved = [];
                //reset edges END

                //RESET NODES - START
                let resetCanvas = initCanvas(nodesJsonData);
                nodesJsonData = resetCanvas['nodesJsonData'];
                let userFilesDeisng = this.state.userFiles.filter(function (item) {
                    return item.name.startsWith('Design');
                });

                let idFile = userFilesDeisng[userFilesDeisng.length - 1].id;
                this.setState({idJsonFile: idFile});            
                this.handleImportJson(e, idFile);
                //RESET NODES - END
        }

        if (this.state.newRole == "Model"){

            this.setState({stateMoveNodes: true})            
            // 1.redraw edges to klikatice

            let borderNodes = nodesJsonData.filter(function (item) {
                return item.group =="border";
            });


            let filteredJson = nodesJsonData.filter(function (item) {
                return item.group !=="grid" && item.group !== "grid-parent" && item.type !=="parent";
            });
            let edges = [];
            let edgesToDelete = [];
            filteredJson.forEach(item => {
                edges.push({
                    "id" : item.id,
                    "edges" : this.network.getConnectedEdges(item.id)
                })

                //delete direct edges in design
                let connectedEdges = this.network.getConnectedEdges(item.id);
                connectedEdges.forEach(item => {
                    edgesToDelete.push(item)
                });

            })
            //get nodes for review edges
            let edgesToNewDraw = getConnectedNodes(edges);

            edgesToDelete.forEach(item => {
                 this.handleHideEdgeOne(item);
             });

            //review edges

            edgesToNewDraw.forEach(item=> {
          
               let node2 = getNodeInfo(item.to, nodesJsonData);
               let node1 = getNodeInfo(item.from, nodesJsonData);

                let arrayOfEdges = [];
                arrayOfEdges = getPath(
                    parseInt(node2.node.x),
                    parseInt(node2.node.y),  
                    parseInt(node1.node.x), 
                    parseInt(node1.node.y), 
                    node2.node.size, 
                    node1.node.size,
                    node2.node.image, 
                    node1.node.image,
                    GetCentersForEdges(nodesJsonData, positionCenterFiltered, parseInt(node1.node.x), parseInt(node1.node.y), parseInt(node2.node.x),parseInt(node2.node.y), node1.node.size)
                    );
                arrayOfEdges.forEach(item => {
                    nodesJsonData[item.id-1].visible = true;
                    //TO DO: najit tri nejblizsi centry nodes a pokud nemaji world, tak ho vytvorit
                    let nodesToGenerateTemplate = getNearestCentersNodes(nodesJsonData[item.id-1].x, nodesJsonData[item.id-1].y, borderNodes, this.state.centers, 3, 3);
                    this.generateTemplate(nodesToGenerateTemplate);
                    //example: this.generateTemplate(['11', '22', '33']);
                }) 

                //refresh data!
                this.generatePathFromEdges(node1.node.id, node2.node.id, arrayOfEdges, node1.node.size);


            })
    
            this.filterByLevel(this.state.level);

        }

        if (this.state.newRole == "Virtual"){

            this.setState({stateMoveNodes: false})


            // generate FLOW nodes with dialogs
            this.state.edgesSaved.forEach(item =>{

                //TO DO: from center node to side node
                let nodeInfoEnd = visSelectNodeInfo(item.idEnd,nodesJsonData);
               // let newNodeEnd = createFlowNodes(nodeInfoEnd.x, nodeInfoEnd.y, nodesJsonData.length+1);
                //nodesJsonData.push(newNodeEnd);

                //item.path.unshift({
                  //  id: item.idEnd,
                  //  x: nodeInfoEnd.x,
                  //  y: nodeInfoEnd.y
               // })

    

                item.path.forEach((itemPath, key, arr) => {
                    let newNode = createFlowNodes(itemPath.x, itemPath.y, nodesJsonData.length+1);
                    nodesJsonData.push(newNode);

                    let prevId = newNode.id - 1;
                    let nextId = newNode.id + 1;

                    //first item not has prevID
                    if (key == 0){
                        prevId = "";
                    };

                    //last item not has nextID
                    if (key == arr.length - 1){
                        nextId = "";
                    }

                    let newDialog = {
                        "id" : newNode.id,
                        "idedge" : item.idEdges,
                        "action" : "action_" + newNode.id,
                        "reaction" : {
                            1 : "Reakce 1_" + newNode.id,
                            2 : "Reakce 2_" + newNode.id,
                            3 : "Rekace 3_" + newNode.id,
                            4 : "Reakce 4_" + newNode.id,
                        },
                        "prevId" : prevId,
                        "nextId" : nextId
                    }
    
                    //SAVE DIALOG
                    this.setState(prevState => {
                        return{
                            ...prevState, stateDialog: [...prevState.stateDialog, newDialog ]
                        }
                    });

                });

            

            });

            this.filterByLevel(this.state.level);

            
        }


        this.handleCloseModal("modalInfo");



    }

    handleOpenModal = ( idModal ) => {document.getElementById(idModal).style.display = "block";}


    handleOpenModalDialog = ( idModal ) => {
        document.getElementById(idModal).style.display = "block";

        //get mainEdge
        let mainEdge = this.state.modalDialog.idEdges;

        //find all nodes in mainEdge and change image
        this.state.stateDialog.forEach(item=>{
            if (item.idedge == mainEdge){
                nodesJsonData[item.id-1].image = "img/circle-size-1-flow-15.svg";
            } else{
                nodesJsonData[item.id-1].image = "img/circle-size-1-flow.svg";
            }
        })

        //set image from start to node

        this.filterByLevel(this.state.level);

    }


    handleCloseModalDialog = (e) => {
        e.preventDefault();

        this.setState({otherWays: ""});
        //reset images
        this.state.stateDialog.forEach(item=>{
            nodesJsonData[item.id-1].image = "img/circle-size-1-flow.svg";
        })
        document.getElementById("modalDialog").style.display = "none";
        this.filterByLevel(this.state.level);
    }


    handleCloseModal = ( idModal ) => {document.getElementById(idModal).style.display = "none";}

    handleAddRosette = (dX, dY) => {
        this.network.unselectAll();
        let dataSize = 3;
        let dataLevel = 1;
        let x = dX + this.state.positionCanvasX;
        let y = dY + this.state.positionCanvasY;
        let dataShape = 'img/hexagon-size-3.svg';
        let center2 = this.getCenter(x, y, dataSize, "parent");
        let dropX = center2[0];
        let dropY = center2[1]
        let group = nodesJsonData.length+1;
     
        nodesJsonData.push({ 
             id: nodesJsonData.length+1, 
             label: "",
             shape: 'image', 
             image: dataShape, 
             x: dropX, 
             y: dropY,
             level: dataLevel, 
             size: dataSize,
             group: "turtle" + group,
             title: "paretnR - main",
             type: "rosetteParentMain",
             textarea: "",
             visible : true
        });

        // set center, where was node dropped as not empty!
             this.setState( state => {
                 return{
                     centers: state.centers.map(item => 
                      (item.type === "parent" && item.x === dropX && item.y === dropY) ? ({...item, parentR: false}) : ({...item})
                     )
                 }
            });

         let centers = this.getCentresNode(center2[0], center2[1], dataSize, "parent");
         //center Turtle

         //add 6 nodes to turtle --> TO DO...stred techto 6 node musi byt pro ostatni nody childs tutle not empty
         for (let i = 1; i < 7; i++) {
            nodesJsonData.push({ 
                id: nodesJsonData.length+1, 
                //label: 'Rossete - child: ' + i, 
                label: "",
                shape: 'image', 
                image: 'img/hexagon-size-3.svg', 
                x: centers[i].x, 
                y: centers[i].y,
                level: dataLevel, 
                size: dataSize,
                group: "turtle" + group,
                title: "rosette-child",
                type: "rosetteParentChild",
                visible: true
            });

            this.setState( state => {
                return{
                    centers: state.centers.map(item => 
                     (item.type === "parent" && item.x === centers[i].x && item.y === centers[i].y) ? ({...item, parentR: false}) : ({...item})
                    )
                }
           });
        }
         // Refresh canvas
         this.filterByLevel(this.state.level);
    }

    logData = () =>{importJson(urlApi, this.callbackUpdateLog, this.state.idLogFile);}
    callbackUpdateLog = (data) => {
        data['logMain'].push({"Date": new Date(), "login": this.props.loginUser ,"user": this.state.user, "text": this.state.consoleLog});
        updateFile(urlApi, this.state.idLogFile ,  JSON.stringify(data) )
    }

    handleAddChildRosette = (dX, dY) => {
        this.network.unselectAll();
        let dataSize = 2;
        let dataLevel = 1;
        let x = dX + this.state.positionCanvasX;
        let y = dY + this.state.positionCanvasY;
        let dataShape = 'img/hexagon-size-2.svg';
        let center2 = this.getCenter(x, y, dataSize, "child");
        let dropX = center2[0];
        let dropY = center2[1]
        let group = nodesJsonData.length+1;
     
        nodesJsonData.push({ 
             id: nodesJsonData.length+1, 
             label: "",
             shape: 'image', 
             image: dataShape, 
             x: dropX, 
             y: dropY,
             level: dataLevel, 
             size: dataSize,
             group: "turtle" + group,
             title: "paretnR - main",
             type: "rosetteChildMain",
             textarea: "",
             visible: true
        });

        // set center, where was node dropped as not empty!
             this.setState( state => {
                 return{
                     centers: state.centers.map(item => 
                      (item.type === "child" && item.x === dropX && item.y === dropY) ? ({...item, childR: false}) : ({...item})
                     )
                 }
            });

         let centers = this.getCentresNode(center2[0], center2[1], dataSize, "child");
         //center Turtle

         for (let i = 1; i < 7; i++) {
            nodesJsonData.push({ 
                id: nodesJsonData.length+1, 
                //label: 'Rossete - child: ' + i, 
                label: "",
                shape: 'image', 
                image: 'img/hexagon-size-2.svg', 
                x: centers[i].x, 
                y: centers[i].y,
                level: dataLevel, 
                size: dataSize,
                group: "turtle" + group,
                title: "rosette-child",
                type: "rosetteChildChild",
                visible: true
            });

            this.setState( state => {
                return{
                    centers: state.centers.map(item => 
                     (item.type === "child" && item.x === centers[i].x && item.y === centers[i].y) ? ({...item, childR: false}) : ({...item})
                    )
                }
           });
        }

         this.filterByLevel(this.state.level)
    }

    handleRefresh = () =>{
        let groupsId = [];
        let nodeSize2 = nodesJsonData.filter( item => item.size === 2);
        nodeSize2.forEach(item =>{
            groupsId.push(item.id);
        })
        //select other nodes with same group
         this.network.setSelection({
             nodes: groupsId
         });
    }

    handleInputTitle = (event) => {
        this.setState({inputTitle: event.target.value});
    }

    handleInputDialog = (e) => {

        this.setState({stateSaveDialog: true});
        this.setState(prevState => ({
            modalDialog: {
              ...prevState.modalDialog,           
              reaction: {                     
                ...prevState.modalDialog.reaction,   
                [e.target.name]: e.target.value         
              }
            }
          }))
    }

    handleTextAreaDialog = (e) => {

        this.setState({stateSaveDialog: true});
        this.setState(prevState => ({
            modalDialog: {
              ...prevState.modalDialog,           
              action:  e.target.value         
            }
          }))
    }

    handleTextAreaModalNode = (event) => {
        this.setState({textAreaModalNode: event.target.value});
    }

    handleHideMenu(){
        this.setState(state => ({activeMenu: !state.activeMenu}));
    }

    handleChangeCenterWorld = (event) => {
        this.handleCloseModal("modalFile");
        this.setState({stateSetCenterWorld : true});
    }

    handleMasterEdge = () => {
        console.log("Master Edge")
        this.setState(state => ({stateMasterEdge: !state.stateMasterEdge}));
    }

    handleStateSaveDialog = (bool) => {
        this.setState(state => ({stateSaveDialog: bool}));
    }

    handleOpenChildNode = (e, id) => {

        console.log(e, id);

        e.preventDefault();

        if (!this.state.stateSaveDialog){

    
            //resets
            this.setState({otherWays: ""});
              //reset images
        

            let nodeInfo = visSelectNodeInfo(id,nodesJsonData);
            document.getElementById("modalDialog").style.display = "none";

            this.setState({inputID: id});

            //is other way?
            nodesJsonData.forEach( item => {
                if (item.group === "dialogElement" && item.x === nodeInfo.x && item.y === nodeInfo.y && item.id != id ){
                    this.setState(prevState => {
                        return{
                            ...prevState, otherWays: [...prevState.otherWays, item.id ]
                        }
                    });
                }
            })

            // set dialog
            this.state.stateDialog.forEach(item =>{
                if (item.id == id){
                    this.setState({
                        modalDialog:  {
                            "reaction" : item.reaction,
                            "idEdges" : item.idedge,
                            "prevId" : item.prevId,
                            "nextId" : item.nextId,
                            "action" : item.action
                        }
                    });

                    this.selectWayOfNode(id, item.idedge);

                }
            });

              //get mainEdge

            this.filterByLevel(this.state.level);
            this.handleOpenModal("modalDialog");

            

        } else{
            this.handleOpenModal("modalSaveDialog");
        }
    }

    handleSaveModalNodes = (e) =>{
        e.preventDefault();
        if (this.state.inputID && this.state.inputTitle){
            //TO DO if ID is empty
            nodesJsonData[this.state.inputID-1].label = this.state.inputTitle;
            nodesJsonData[this.state.inputID-1].textarea = this.state.textAreaModalNode;

            this.filterByLevel(this.state.level);
            document.getElementById("modalTitle").style.display = "none";
        }
    }

    handleSaveDialog = (e) =>{
        e.preventDefault();
        document.getElementById("modalDialog").style.display = "none";

        this.state.stateDialog.forEach(item =>{
            if (item.id == this.state.inputID){
                item.reaction = this.state.modalDialog["reaction"];
                item.action = this.state.modalDialog["action"];
            }
        })
    }

    handleSaveDialogYesNo = (e) =>{
        e.preventDefault();
        document.getElementById("modalSaveDialog").style.display = "none";
        this.state.stateDialog.forEach(item =>{
            if (item.id == this.state.inputID){
                item.reaction = this.state.modalDialog["reaction"];
                item.action = this.state.modalDialog["action"];
            }
        })

        this.handleStateSaveDialog(false);
        this.handleOpenModal("modalDialog");

    }

    onNewShapeDrop = (e) => {
        e.preventDefault()  
        let dataSize = +e.dataTransfer.getData("size");
        let dataShape = e.dataTransfer.getData("src")
        let dataLevel = +e.dataTransfer.getData("level")

        //ANIMACE SVG
        // 1. krok zjisteni, kdy byl element polozen z events souradnice x (e.clientX), y (e.clientY) a 
        // 2. krok - pripocte se k tomu poloha canvasu (this.state.positionCanvasX, this.state.positionCanvasY)
        // 3. krok - zjisteni pozice nejlbizsiho stredu (this.getCenter)

        switch (dataShape){
            case "img/turtle-size-3.svg":
                this.handleAddRosette(e.clientX, e.clientY);
                break;

            case "img/turtle-size-2.svg":
                this.handleAddChildRosette(e.clientX, e.clientY);
                break;
            default:
                        //center insert shape
        
        let center1 = this.getCenter(e.clientX + this.state.positionCanvasX , e.clientY + this.state.positionCanvasY, dataSize);

        let dropX = center1[0];
        let dropY = center1[1];

         nodesJsonData.push({ 
             id: nodesJsonData.length+1, 
             label: 'Drop Element - size: ' + dataSize, 
             shape: 'image', 
             image: dataShape, 
             x: dropX, 
             y: dropY,
             level: dataLevel, 
             size: dataSize,
             group: "groupDropElement" + this.state.idJsonFile,
             title: "DropTitle",
             type: "dropElement",
             textarea: "",
             visible: true
         });

         //set center, where was node dropped as not empty!
            this.setState( state => {
                return{
                    centers: state.centers.map(item => 
                     (item.x === dropX && item.y === dropY) ? 
                     (dataSize == 1 && item.type === "child") ?
                     ({...item, empty1: false}) : 
                     (dataSize == 2 && item.type === "child") ?
                     ({...item, empty2: false}) :
                     (dataSize == 3 && item.type === "parent") ?
                     ({...item, empty3: false}) :
                     ({...item}) : ({...item})
                    )
                }
            });
        }

        //default

        let centerCanvas = {x: 0, y: 0};

    
         this.filterByLevel(this.state.level, centerCanvas);

         this.setState( {consoleLog: "Shape was added."}); 
         this.logData();


         
    }

    render() {
        return (
            <>
                <a className={this.state.activeMenu === true ? "active" : ""} id="btn-menu" onClick={() => this.handleHideMenu()}></a> 
                <div className={this.state.activeMenu === true ? "active col" : "col"} id="rootvis" onDrop={this.onNewShapeDrop} onDragOver={e => e.preventDefault()}>
                    <div className="rootVis" ref={this.domNode} />
                </div>
                <Menu1 
                    handleRefresh = {this.handleRefresh}
                    handleSaveCanvas = {this.handleSaveCanvas}
                    handleLogout = {this.handleLogout}
                    handleOpenModal = {this.handleOpenModal}
                    handleSelectChange = {this.handleSelectChange}
                    handleSelectRoleChange = {this.handleSelectRoleChange}
                    handleImportFile = {this.handleImportFile}
                    userName = {this.props.loginUser}
                    userRole = {this.state.user.role}
                    userFiles = {this.state.userFiles}
                    idJsonFile = {this.state.idJsonFile}
                    activeMenu = {this.state.activeMenu}
                    activeBtnGrid = {this.state.activeBtnGrid}
                    handleGrid = {this.handleGrid}
                    usersRoles = {this.state.usersRoles}
                    activeRole = {this.state.activeRole}
                    stateImportMindbox = {this.state.stateImportMindbox}
                    stateImportMetila = {this.state.stateImportMetila}

                />
                <Menu2
                        handleAddEdge = {this.handleAddEdge}
                        handleNewEdge = {this.handleNewEdge}
                        handleDeleteEdges = {this.handleDeleteEdges}
                        handleEdgesType = {this.handleEdgesType}
                        handleMasterEdge = {this.handleMasterEdge}
                        addEdge = {this.state.addEdge}
                        selectedEdges = {this.state.selectedEdges}
                        level = {this.state.level}
                        activeMenu = {this.state.activeMenu}
                        handleSetLevel = {this.handleSetLevel}
                        handleZoom = {this.handleZoom}
                        edgesType = {this.state.edgesType}  
                        handleOpenModal = {this.handleOpenModal}
                        stateDropNode = {this.state.stateDropNode}
                        activeRole = {this.state.activeRole}
                        stateMasterEdge = {this.state.stateMasterEdge}

                    />

                <ModalTitle inputID = {this.state.inputID} inputTitle = {this.state.inputTitle} textAreaModalNode = {this.state.textAreaModalNode}
                        handleInputTitle = {this.handleInputTitle}
                        handleTextAreaModalNode = {this.handleTextAreaModalNode}
                        handleSaveModalNodes = {this.handleSaveModalNodes}
                />

                <ModalDialog 
                        inputID = {this.state.inputID}
                        edgesMainID = "main edges ID: 123"
                        handleSaveDialog = {this.handleSaveDialog}
                        modalDialog = {this.state.modalDialog}
                        handleInputDialog = {this.handleInputDialog}
                        handleTextAreaDialog = {this.handleTextAreaDialog}
                        handleOpenChildNode = {this.handleOpenChildNode}
                        otherWays = {this.state.otherWays} 
                        handleCloseModalDialog = {this.handleCloseModalDialog}
                />

                <ModalSaveDialog
                        handleSaveDialogYesNo = {this.handleSaveDialogYesNo}
                        handleStateSaveDialog = {this.handleStateSaveDialog}
                />

                <ModalFile
                    userFiles = {this.state.userFiles}
                    handleCloseModal= {this.handleCloseModal}
                    handleSelectChange = {this.handleSelectChange}
                    handleImportJson = {this.handleImportJson}
                    stateCenterWorldX = {this.state.centerWorldX}
                    stateCenterWorldY = {this.state.centerWorldY}
                    handleChangeCenterWorld = {this.handleChangeCenterWorld}
                />

                <ModalFileMetila
                    handleCloseModal= {this.handleCloseModal}
                />

                <ModalSaveCanvas 
                           userFiles = {this.state.userFiles}
                           handleCloseModal= {this.handleCloseModal}
                           handleSelectChange = {this.handleSelectChange}
                           handleSaveCanvas = {this.handleSaveCanvas}
                           handleSaveCanvasAs = {this.handleSaveCanvasAs}
                />

                <ModalInfo 
                           handleCloseModal= {this.handleCloseModal}
                           handleSelectRoleAbort = {this.handleSelectRoleAbort}
                           handleSelectRoleChangeRun = {this.handleSelectRoleChangeRun}
                           activeRole = {this.state.activeRole}
                           headline = "INFO - Change role"
                           text = { "Do you realy change Role?" }
                />

                <ModalSaveCanvasAs
                handleCloseModal= {this.handleCloseModal}
                handleCreateFile= {this.handleCreateFile}
                handleInputNameChange = {this.handleInputNameChange}
                nameNewFile = {this.state.nameNewFile}
                />

                <ModalDeleteEdge 
                    handleCloseModal= {this.handleCloseModal}
                    handleDeleteEdge = {this.handleDeleteEdge}
                    selectedEdges = {this.state.selectedEdges}
                />

                <div id="consoleLog">{this.state.consoleLog}</div>
            </>
        );
    }
}
export default ZoomAndLevels;
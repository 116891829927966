import React, { Component } from 'react';

class ModalDeleteEdge extends Component {

    constructor(props){
        super();
    }

    render() {
        return(
        <div id="modalDeleteEdge" className="modal">
            <div className="modal-body">
                <h2>Delete Edges</h2>
                <form>

                    <div className="form-row">
                        <p>Do you delete EDGE: {this.props.selectedEdges[0]}?</p>
                    </div>

                    <div className="modal-btns">
                        <button onClick={() => this.props.handleDeleteEdge()}>DELETE</button>
                        <button className="btn-cancel" onClick={() => this.props.handleCloseModal("modalDeleteEdge")}>CANCEL</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

export default ModalDeleteEdge;  
import React, { useState } from 'react';
import Login from './components/Login/Login';
import ZoomAndLevels from './components/zoom+level';

import './App.css';

const App = () => {

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [username, setUsername] = useState(localStorage.getItem("username"));

  // not login user
   if(!token) {
     return <Login setToken={setToken} setUsername={setUsername} />
   }

  // login user
  return (
      <div className="app">
        <div className="main">
          <ZoomAndLevels loginUser={username}/>
        </div>
      </div>
  );
}

export default App;

import React, { Component } from 'react';
import Shape from '../Shape/Shape';

import './menu.css';

class Menu2 extends Component {

    constructor(props){
        super()
    }



    render() {

        const levels = [-1, 0, 1];


        
        return(


            
            
            <div id="nav-actions" className={ this.props.activeMenu === true ? "active actions" : "actions"} >
                <div>
                    <svg className="zoomIn" onClick={() => this.props.handleZoom('IN')} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /><path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" /></svg>
                    <svg className="zoomOut" onClick={() => this.props.handleZoom('OUT')} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z" /></svg>
                </div>

                <div className="levelWrap">
                    <div>Level:</div>
                    <div className="levelWrap__inner">
                        {levels.map(x => <div className={this.props.level === x ? "levelBtn active" : "levelBtn"} onClick={() => { this.props.handleSetLevel(x) }} key={x}>{x}</div>)}
                    </div>
                </div>

                <section className={(this.props.stateDropNode === true ? "" : "disable") + (this.props.activeRole === "Virtual" ? " invisible" : "")} id="dropNodes">
                    <div className="shapes--menu shapes-menu--hexagon d-flex align-center" data-title="Hexagon">
                        <Shape src={"img/hexagon-size-3.svg"} size={3} level={1} type="hexagon"></Shape>
                        <Shape src={"img/hexagon-size-2.svg"} size={2} level={1} type="hexagon"></Shape>
                        <Shape src={"img/hexagon-size-1.svg"} size={1} level={1} type="hexagon"></Shape>
                    </div>    
                    <div className="shapes--menu shapes-menu--triangle-up d-flex align-center" data-title="TriangleUp">
                        <Shape src={"img/triangleup-size-3.svg"} size={3} level={1} type="triangle"></Shape>
                        <Shape src={"img/triangleup-size-2.svg"} size={2} level={1} type="triangle"></Shape>
                        <Shape src={"img/triangleup-size-1.svg"} size={1} level={1} type="triangle"></Shape>
                    </div>
                    <div className="shapes--menu shapes-menu--circle d-flex align-center" data-title="Circle">
                        <Shape src={"img/circle-size-3.svg"} size={3} level={1} type="circle"></Shape>
                        <Shape src={"img/circle-size-2.svg"} size={2} level={1} type="circle"></Shape>
                        <Shape src={"img/circle-size-1.svg"} size={1} level={1} type="circle"></Shape>
                    </div>
                    <div className="shapes--menu shapes-menu--triangle-down d-flex align-center" data-title="TriangleDown">
                        <Shape src={"img/triangledown-size-3.svg"} size={3} level={1} type="triangle"></Shape>
                        <Shape src={"img/triangledown-size-2.svg"} size={2} level={1} type="triangle"></Shape>
                        <Shape src={"img/triangledown-size-1.svg"} size={1} level={1} type="triangle"></Shape>
                    </div>
                    <div className="shapes--menu shapes-menu--square d-flex align-center" data-title="Square">
                        <Shape src={"img/square-size-3.svg"} size={3} level={1} type="square"></Shape>
                        <Shape src={"img/square-size-2.svg"} size={2} level={1} type="square"></Shape>
                        <Shape src={"img/square-size-1.svg"} size={1} level={1} type="square"></Shape>
                    </div>
                    <div className="shapes--menu shapes-menu--turtle d-flex align-center" data-title="Turtle">
                        <Shape src={"img/turtle-size-3.svg"} size={3} level={1} type="turtle"></Shape>
                        <Shape src={"img/turtle-size-2.svg"} size={2} level={1} type="turtle"></Shape>
                    </div>
                </section>
   
                    <div id="menu-edges">
                        Edges:
                    <ul >
                        <li className="nav-item">
                            <a href="#" className={this.props.addEdge === true ? "nav-link nav-link--btn active" : "nav-link nav-link--btn"} onClick={() => this.props.handleAddEdge("toggle")}>
                            {this.props.addEdge === true ? "ADD on" : "ADD off"}
                            </a>
                            <button onClick={(event) => this.props.handleEdgesType(event)} className="edges-type">{this.props.edgesType}</button>
                        </li>
                        <li className="nav-item">
                            <a href="#" className={this.props.selectedEdges == "" ? "nav-link disable" : "nav-link active"} onClick={() => 
                                {
                                    if (this.props.selectedEdges != "")
                                    {
                                    this.props.handleOpenModal("modalDeleteEdge")}
                                }
                                    
                                    }>
                            Delete edge
                            </a>
                        </li>
                        <li>
                            <a href="#" className={(this.props.activeRole !== "Virtual" ? " invisible" : "nav-link") + (this.props.stateMasterEdge === true ? " active" : " ") } onClick={() => this.props.handleMasterEdge()}>Master Edge</a>
                        </li>
                    </ul>
                    </div>
                    

            </div>
        )
    }
}
  
  
export default Menu2;  
  

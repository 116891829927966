     export function GetCentersForEdges(nodesJsonData, positionCenterFiltered, x1, y1, x2, y2, sizeNodes){
        let centersParent = [];
        let centersFinal = [];
        let centerDelete = [];
        let positionsX = [x1, x2];
        let positionsY = [y1, y2]; 
        let offset = 220; //size 3 --> 220
        let typeCenter;
        let typeNodes;

        switch (sizeNodes){
            case 2:
                typeCenter = "child";
                typeNodes = "grid";
            break;
            case 3:
                typeCenter = "parent";
                typeNodes = "grid-parent";
            break;
            default:
                typeCenter = "parent";
                typeNodes = "grid-parent";     
        }

        //offset - for centers left, right, top and bottom off nodes moves.

        positionsX.sort(function(a, b) {return a - b;});
        positionsY.sort(function(a, b) {return a - b;});


        //type of Centre: "parent" or "child"
        positionCenterFiltered.forEach(item=>{
            if (item.type === typeCenter){
                centersParent.push({
                    "x" : item.x,
                    "y" : item.y,
                    "id" : item.id,
                })
            }

        });

        //only grid-parent nodes
        nodesJsonData.forEach(item =>{
            if (item.group === typeNodes && item.x >= positionsX[0]-offset && item.x<=positionsX[1]+offset && item.y >= positionsY[0]-offset && item.y <= positionsY[1]+offset){
                centersFinal.push({
                    "x" : item.x,
                    "y" : item.y,
                    "id" : item.id,
                    "type" : item.group
                })
            }
        });

           //delete centers of hexagons grid, to not use for generate path for edges
           centersFinal.forEach(item =>{
            let x = item.x;
            let y = item.y;
            let id = item.id;

            centersParent.forEach(item =>{
                if (item.x == x && item.y == y){
                    centerDelete.push({
                        "id" : id,
                        "x" : x,
                        "y" : y
                    });
                }
            })
        })
        centerDelete.forEach(e=>{centersFinal = centersFinal.filter(item => item.id !== e.id);});
      
     return centersFinal;
     }
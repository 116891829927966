
export function activeParentHexa (x,y, nodesJsonData){

        let activeId = []; 

        let filterNodesJson = nodesJsonData.filter(item => 
            item.group === "border" && 
            item.type === "parent" &&
            parseInt(item.x) >= parseInt(x) - 50 &&
            parseInt(item.x) <= parseInt(x) + 50 &&
            parseInt(item.y) >= parseInt(y) - 50 &&
            parseInt(item.y) <= parseInt(y) + 60
        );

        filterNodesJson.forEach(item => {
                activeId.push(item.id)
        })

        return activeId;
}
import { divide } from 'lodash';
import React, { Component } from 'react';
import './menu.css';

class Menu1 extends Component {

    constructor(props){
        super()
    }


    handleHideMenu(){
        this.setState(state => ({
            activeMenu: !state.activeMenu
          }));
    }



    handleSaveCanvasAsImage(){
        let canvas = document.querySelector('canvas')
        let downloadLink = document.createElement('a');
        let nameOfFile = "Canvas_" + this.props.userName + ".png";
        downloadLink.setAttribute('download', nameOfFile);
        canvas.toBlob(function(blob) {
            let url = URL.createObjectURL(blob);
            downloadLink.setAttribute('href', url);
            downloadLink.click();
        });
    }

    render() {
        return(

        
        <div id="nav-main" className={this.props.activeMenu === true ? "active" : ""}>
        <nav id="navigation">
            <ul className="menu--main">
                <li className="item-menu-main">
                    FILE
                    <ul className="menu--submenu">
                        <li className="nav-item">
                            <a href="#" className={this.props.stateImportMindbox === true ? "nav-link" : "nav-link disable"} onClick={() => {
                                if (this.props.stateImportMindbox === true)
                                    {
                                    this.props.handleOpenModal("modalFile")
                                    }
                                }}>
                            Import MindBox
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className={this.props.stateImportMetila === true ? "nav-link" : "nav-link disable"} onClick={() => {
                                if (this.props.stateImportMetila === true)
                                    {
                                    this.props.handleOpenModal("modalFileMetila")
                                    }
                                }}>
                            Import Metila
                            </a>
                        </li>
                        <li className="nav-item">
                        <a href="#" className="nav-link" onClick={() => this.props.handleOpenModal("modalSaveCanvas")}>
                            SAVE Canvas
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => this.handleSaveCanvasAsImage()}>
                            EXPORT to IMG
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="menu--user">
                <li className="loginUser"><span>USER: </span>{this.props.userName}</li>
                <li className="userRole"><span>ROLE: </span>{this.props.userRole}</li>
                <li className="userRole">
                <select onChange={(event) => this.props.handleSelectRoleChange(event)} id="selectRole">
                            {this.props.usersRoles.map(list => <option value={list} key={list}>{list}</option>)}
                        </select>
                </li>
                <li className="nav-item">
                    <a href="#" className="nav-link nav-link--logout" onClick={() => this.props.handleLogout()}>
                    LogOut
                    </a>
                </li>
                    <svg className={this.props.activeBtnGrid === true ? "btn--grid active" : "btn--grid"} onClick={() => this.props.handleGrid()} viewBox="0 0 32 32" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path d="M6,0H2C0.895,0,0,0.896,0,2v4c0,1.105,0.895,2,2,2h4c1.105,0,2-0.895,2-2V2C8,0.896,7.105,0,6,0z M6,6H2V2   h4V6z" fill="#ffffff"/><path d="M18,0h-4c-1.105,0-2,0.896-2,2v4c0,1.105,0.895,2,2,2h4c1.104,0,2-0.895,2-2V2C20,0.896,19.104,0,18,0z    M18,6h-4V2h4V6z" fill="#ffffff"/><path d="M30,0h-4c-1.105,0-2,0.896-2,2v4c0,1.105,0.895,2,2,2h4c1.104,0,2-0.895,2-2V2C32,0.896,31.104,0,30,0z    M30,6h-4V2h4V6z" fill="#ffffff"/><path d="M6,12H2c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.105,0,2-0.895,2-2v-4C8,12.896,7.105,12,6,12z M6,18H2v-4h4V18z" fill="#fff"/><path d="M18,12h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C20,12.896,19.104,12,18,12z M18,18h-4v-4h4V18z" fill="#ffffff" /><path d="M30,12h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C32,12.896,31.104,12,30,12z M30,18h-4v-4h4V18z" fill="#ffffff" /><path  d="M6,24H2c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.105,0,2-0.895,2-2v-4C8,24.896,7.105,24,6,24z M6,30H2v-4h4V30z" fill="#ffffff" /><path  d="M18,24h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C20,24.896,19.104,24,18,24z M18,30h-4v-4h4V30z" fill="#ffffff" /><path  d="M30,24h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C32,24.896,31.104,24,30,24z M30,30h-4v-4h4V30z" fill="#ffffff" />
                        </g>
                    </svg>
            </ul>
        </nav>

        </div>      

        )
      
    }
}
  
  
export default Menu1;  
  


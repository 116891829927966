import { edgesAdd } from "../Edges/Edges";

export function getUserInfo(urlApi, callback){
    {
        fetch(urlApi + "user", {
            "method": "GET",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
            })
            .then(response => response.json())
            .then(result => {
                callback(result.user);
            })
            .catch(err => {
            console.error(err);
        });
    }
}

export function updateFile(urlApi, id, body){

    {
        fetch(urlApi + "file/testradek/update/" + id , {
            "method": "POST",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem("token")
            },  "body": body
            })
            .then(response => response.json())
            .then(result => {
                console.log("logged")
            })
            .catch(err => {
            console.error(err);
        });
    }
}



export function renameFile(urlApi, callback, id, name){
    {
        fetch(urlApi + "file/testradek/rename/" + id , {
            "method": "POST",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem("token")
            },  "body": "{\"name\":\""+name+"\"}"
            })
            .then(response => response.json())
            .then(result => {
                callback(result.user);
            })
            .catch(err => {
            console.error(err);
        });
    }
}


export function createNewFile(urlApi, callback, nameOfFile){
    {
        fetch(urlApi + "file/testradek/create", {
            "method": "POST",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }, "body": "false"
            })
            .then(response => response.json())
            .then(result => {
                callback(result, nameOfFile);
            })
            .catch(err => {
                console.log(err);
        });
    }
}

export function getUserFile(urlApi, callback){
    {
        fetch(urlApi + "file/testradek/list", {
            "method": "GET",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
            })
            .then(response => response.json())
            .then(result => {
                callback(result.list);
            })
            .catch(err => {
            console.error(err);
        });
    }
}


export function saveCanvas (urlApi, data, idFile, edges, edgesAddedUser, callback, masterEdges){
    let edgesHelper = [];
    let edgesFinal = [];
    let versionCanvas = 1; 
    let masterEdge = masterEdges;

    edges.forEach(item=>{

        //Get other edges without this edge
        let edgesFilter = edges.filter(x => x.id !=item.id)

        item.edges.forEach(inner => {
            edgesFilter.forEach(inner2 => {
                inner2.edges.forEach(inner3 =>{
                    if (inner3 == inner){

                        edgesHelper.push({
                            "from" : item.id,
                            "to" : inner2.id,
                            "sum" : item.id + inner2.id
                        })
                    }
                })
            })

        })
    })


        //delete duplicity edges in col sum
        const arrUniq = [...new Map(edgesHelper.map(v => [v.sum, v])).values()]


        //create final lists
        arrUniq.forEach(a=>{
            edgesFinal.push({
                "from" : a.from,
                "to" : a.to,
                "size" : 3
            })
        })

        edgesAddedUser.forEach(es=>{
            edgesFinal.push({
                "from" : es.idStart,
                "to" : es.idEnd,
                "size" : es.sizeEdge
            })
        })

        //add new edges added od Canvas from USER



      data = {
          "nodes": data,
          "edges" : edgesFinal,
          "version" : versionCanvas,
          "master_edge": masterEdge
      }
      fetch(urlApi + "file/testradek/update/" + idFile, {
          "method": "POST",
          "headers": {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("token")
          },
          "body": JSON.stringify(data)    
          })
          .then(response => {

           callback(response)   

          })
          .catch(err => {
         console.error("Error:" + err);
          });

}

export function logOutUser (urlApi){
    fetch(urlApi + "logout", {
        "method": "POST",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
        })
        .then(response => {
            
        })
        .catch(err => {
            
    });

    localStorage.removeItem("token")
    localStorage.removeItem("username")
    window.location.reload(false); 
}

export function createFile (urlApi){
    fetch(urlApi + "file/testradek/create", {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        "body": "false"

        })
        .then(response => response.json())
        .then(value =>{
           this.setState( {
            consoleLog: value
        }); 
        })
        .catch(err => {

            this.setState( {
                consoleLog: err
            }); 
    });

}

export function importJson (urlApi, callback, idFile){
    fetch(urlApi + "file/testradek/read/" + idFile, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
        })
        .then(response => response.json())
        .then(value =>{
           if (value.status != "error"){
            callback(value);
           }

        })
        .catch(err => {
          console.log(err)
    });
}

export function viewImportedData (importedData, nodesJsonData, centerWorld){

    let edgesFinal = [];
    let edgesStart = importedData['edges'];
    let edgesConvert = [];
    let nodes = importedData['nodes'];

    nodes.forEach(item => {
         let shapeSize = conversionShape(item.shape, item.main, item.image, item.size);
        
        edgesConvert.push([item.id, nodesJsonData.length+1]);
         
        nodesJsonData.push({ 
             id: nodesJsonData.length+1, 
             label: item.label, 
             shape: 'image', 
             image: shapeSize[0],
             x: conversionXY("x", item.main, item.x),
             y: conversionXY("y", item.main, item.y),
             level: 1,
             visible: true,
             size: shapeSize[1],
             group: item.group ? item.group : "groupImportedJsonR",
             title: item.title ? item.title : "default title",
             type: item.type ? item.type : "importedJsonData"
         });

    });


    if (edgesStart) {
        edgesStart.forEach(item => {
            convertEdges(item.from, item.to, item.size);
        })
    }

    function convertEdges (from, to, size){
        let fromConvert = from;
        let toConvert = to;
        edgesConvert.forEach(item =>{
            if (item[0] == from) {fromConvert = item[1]}
            if (item[0] == to) {toConvert = item[1]}
        })
       edgesFinal.push({fromConvert, toConvert, size});
    }

    function conversionShape(node, main, imageNode, sizeNode){
        let size;
        let shape;
        switch (node){
            case "box":
                if (main == "yes"){
                    size = 3;
                    shape = 'img/circle-size-3.svg'
                }else{
                    size = 2;
                    shape = 'img/hexagon-size-2.svg';
                }
                break;
            case "dot":
                size = 1;
                shape = 'img/circle-size-1.svg'
                break;    
            case "elipse":
                size = 3;
                shape = 'img/hexagon-size-3.svg'
                break; 
            case "circle":
                size = 3;
                shape = 'img/hexagon-size-3.svg'
                break; 

            case "image":
                shape = imageNode;
                size = sizeNode;
                break; 

            default:
                size = 3;
                shape = 'img/hexagon-size-3-default.svg'
        }
       
        return [shape, size];
    }

    function conversionXY (type, main, coordinates){
        if (type == "x"){
            if (main == "yes") {return centerWorld[0];}
            else return coordinates + centerWorld[0];
        } else{
            if (main == "yes"){return centerWorld[1];}
            else {return coordinates + centerWorld[1];}
        }
    }

    return [nodesJsonData, edgesFinal];
}



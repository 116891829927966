import React, { Component } from 'react';

class ModalSaveCanvas extends Component {

    constructor(props){
        super();
    }

    render() {
        return(
        <div id="modalSaveCanvas" className="modal">
 
            <div className="modal-body">
            <div className="modal-header">
                <h2>SAVE CANVAS</h2>
                <button className="btn--close" onClick={() => this.props.handleCloseModal("modalSaveCanvas")} >X</button>
            </div>  
                <p>Would you like to overwrite the existing file or save a new copy?</p>
                <form>

                    <div className="form-row">
                        <select onChange={(event) => this.props.handleSelectChange(event)}>
                            {this.props.userFiles.map(list => <option value={list.id} key={list.id}>ID:{list.id} | Name: {list.name}</option>)}
                        </select>
                    </div>

                    <div className="modal-btns">
                        <button onClick={() => this.props.handleSaveCanvasAs()}>Save as new Canvas</button>
                        <button className="btn--grey" onClick={() => this.props.handleSaveCanvas()}>OVERWRITE</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

  
export default ModalSaveCanvas;  
import React, { Component } from 'react';

class ModalFileMetila extends Component {

    constructor(props){
        super();
    }

    render() {
        return(
        <div id="modalFileMetila" className="modal">
            <div className="modal-body">
                <div className="modal-header">
                <h2>Import METILA</h2>
                    <button className="btn--close" onClick={() => this.props.handleCloseModal("modalFileMetila")} >X</button>
                </div>  
                <form>

                    <div className="form-row">
                        Under Constructions.
                    </div>

                </form>
            </div>
        </div>
        )
    }
}

export default ModalFileMetila;  
import React, { useState } from 'react';
import './Login.css';


export default function Login({ setToken, setUsername }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();

  var token1 = "";
  var urlToken = 'https://msim.metilachat.cz/admx/login';
  //var urlToken = 'https://apimetix.com/admx/login';

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });

    async function loginUser(credentials) {
      return fetch(urlToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      .then(response => response.json())
     .then(value => {
       token1 = value.token;
       setMessage(value.message);
       if (token1) {
         localStorage.setItem('token', token1);
       }
     
     })
     .catch(err => {
       console.error(err);
     });
     }

    //sent token and username to parent
    setToken(token1);
    setUsername({username}.username);

    //set username to local storage
    localStorage.setItem('username', {username}.username);
  }



  return(
    <div className="login-wrapper">
        <div className="login-wrapper__inner">

        <h1>Login form</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">
            Username
            </label>

            <input type="text" name="username" id="username" onChange={e => setUserName(e.target.value)} />
          <label htmlFor="pass">
            Password
            </label>

            <input type="password" name="pass" id="pass" onChange={e => setPassword(e.target.value)} />
          <button type="submit">Submit</button>
        </form>
        <span className="alert">{message}</span>

      </div>
    </div>
  )
}


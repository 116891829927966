import React, { Component } from 'react';

class Shape extends Component {

    constructor(props){
        super();
    }

    handleDragStart = e => {
       e.target.style.opacity = "0.8";
       e.dataTransfer.setData('size', this.props.size)
       e.dataTransfer.setData('src', this.props.src)
       e.dataTransfer.setData('level', this.props.level)
    }

    handleDragEnd = e => {
        e.target.style.opacity = "1";
     }
 
    render() {
        return(
                    <img 
                        src={this.props.src} 
                        className="hover"
                        draggable="true" 
                        onDragStart={this.handleDragStart} 
                        onDragEnd={this.handleDragEnd} 
                        type={this.props.type}
                        size={this.props.size} 
                        width={
                            (() => {
                                switch (this.props.type) {
                                  case "circle":  return this.props.size == 1 ? 20 : this.props.size == 2 ? 30 : 54;
                                  case "square":  return this.props.size == 1 ? 20 : this.props.size == 2 ? 30 : 65;
                                  default: return this.props.size == 1 ? 20 : this.props.size == 2 ? 35 : 60;
                                }
                              })()
                        }
                    />
        )
      
    }
}
  
export default Shape;  
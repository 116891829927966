
export function createFlowNodes (x,y, id){

    //creta new nodes for click and view dailog
    
    let node = ({ 
        id: id, 
        idPrev: "1",
        idNext: "2",
        label: '', 
        shape: 'image', 
        image: 'img/circle-size-1-flow.svg', 
        x: x, 
        y: y,
        level: 1, 
        size: 1,
        group: "dialogElement",
        title: "Dialog",
        type: "dropElement",
        visible: true,
    });


    return node;
}
import React, { Component } from 'react';

class ModalFile extends Component {

    constructor(props){
        super();
    }

    render() {
        return(
        <div id="modalFile" className="modal">
            <div className="modal-body">
                <h2>Select file for import MINDBOX</h2>
                <form>

                    <div className="form-row">
                        <select onChange={(event) => this.props.handleSelectChange(event)}>
                            {this.props.userFiles.map(list => <option value={list.id} key={list.id}>ID:{list.id} | Name: {list.name}</option>)}
                        </select>
                        <a href="#" className="center-world" onClick={(event) => this.props.handleChangeCenterWorld(event)}>
                                <span>x: {this.props.stateCenterWorldX}</span>
                                <span>y: {this.props.stateCenterWorldY}</span>
                        </a>
                    </div>

                    <div className="modal-btns">
                        <button onClick={(e) => this.props.handleImportJson(e)}>IMPORT</button>
                        <button className="btn-cancel" onClick={() => this.props.handleCloseModal("modalFile")}>CANCEL</button>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}

export default ModalFile;  
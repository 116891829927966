let grid = [];

const a1 = 2 * Math.PI / 6;
const a2 = 2 * Math.PI / 3;
const r = 30;
const hexSize = 30;
let width = 2700;
let height = 2400;
const H = Math.sqrt(3) * hexSize
const S = (3 / 2) * hexSize

function generateGrid(level, size, color, group, a){
    for (let y = r; y + r * Math.sin(a) < height; y += r * Math.sin(a)) {
        for (let x = r, j = 0; x + r * (1 + Math.cos(a)) < width; x += r * (1 + Math.cos(a)), y += (-1) ** j++ * r * Math.sin(a)) {
            grid.push({
                "id": grid.length + 1,
                "shape": "dot",
                "level": level,
                "x": x.toFixed(5),
                "y": y.toFixed(5),
                "color": color,
                "size": size,
                "group": group,
                "type" : "",
                "visible" : false,
                "image" : "",
                "title": "",
                "label" : ""
            });
        };
    };
}

export function getGrid(){
   //Generat child GRID
   generateGrid(1, 1, "#595858", "grid", a2);
   //Generate parent GRID
    generateGrid(1, 2, "#c9dbf7", "grid-parent", a1);
return grid;
}

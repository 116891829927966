export function edgesAdd(sizeNodes, eType){

    let srcArrows;
    let edgesWidth;
    let edgesHeight;

    switch (sizeNodes){
        case 2:
            edgesWidth = [2, 6, 8];
            edgesHeight = [28.5, 30, 30];
        break;
        case 3:
            edgesWidth = [4, 8, 12];
            edgesHeight = [49, 50.5, 51.5];
        break;
        default:
            edgesWidth = [4, 8, 12];
            edgesHeight = [49, 50.5, 51.5];   
    };


    switch (eType) {
        case 1:
            srcArrows = "/img/edges-1.jpg";
            edgesWidth = edgesWidth[0];
            edgesHeight = edgesHeight[0];
            break;
        case 2:
            srcArrows = "/img/edges-2.jpg";  
            edgesWidth = edgesWidth[1];
            edgesHeight = edgesHeight[1];
            break; 
        case 3:
            srcArrows = "/img/edges-3.jpg";
            edgesWidth = edgesWidth[2];
            edgesHeight = edgesHeight[2];
            break;              
        default:
            srcArrows = "/img/edges-1.jpg";   
            edgesWidth = edgesWidth[0];
            edgesHeight = edgesHeight[0];
    }

    return [srcArrows, edgesWidth, edgesHeight];

}

    
    let centers = [{}];

    function getCenterOfShapes(data, start, offset, count, type){
        for (let step = start; step < start + count; step = step + offset) {
            centers.push({
                "id" : centers.length,
                "x" : data[step-1].x,
                "y" :data[step-1].y,
                "empty" : true,
                "idnode" :  data[step-1].id,
                "type": type,
            });
        }
    }

    export function getCenters(data){
        for (let x = 1; x < 7966; x = x + 531){
            getCenterOfShapes(data, x, 6, 174, "parent");
            getCenterOfShapes(data, 180 + x, 6, 174, "parent");
        }

        for (let x = 1; x < 7966; x = x + 177){
            getCenterOfShapes(data, x, 6, 175, "child");
        }
        for (let x = 4; x < 7966; x = x + 177){
            getCenterOfShapes(data, x, 6, 175, "child");
        }

        const positionCenterFiltered = centers.filter(x => x.id != undefined);
       
     return positionCenterFiltered;
     }